import { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { flatten, pick } from 'lodash-es';
//  REMOVE TAG HIDE FOR FIRST VERSION
// import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Localization, MenuItem, SideNav, NavBar, useWindowSize } from 'components';
import { useUserStore } from 'stores';
import { ROUTES } from 'enums';
import {
  Footer,
  HotelsHome,
  HotelDetails,
  HotelsListing,
  NotFound,
  PrivacyPolicy,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // SubscriptionPage
  TermsAndConditions,
} from './public';
import {
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // BuySubscription
  HotelCheckout,
  MyBookings,
  MyBookingsDetails,
  MyProfile,
  SuccessCheckout,
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // SuccessCancelBooking
  TransactionDetails,
  TransactionsPage,
} from './private';
import { PublicRoutes } from './public/PublicRoutes';
import { PrivateRoutes } from './private/PrivateRoutes';
// REMOVE TAG HIDE FOR FIRST VERSION
// import subscriptionIcon from 'assets/images/subscription-icon.svg';
import { mainContainer } from 'styles/globalCss';
import { contentContainer, horizontalLine, mobileActionButton } from './styles';

export const Routes = () => {
  const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const { getText } = useTranslations();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const [isMobile, setIsMobile] = useState(windowWidth <= 767);
  const allRoutes = [...Object.values(PrivateRoutes), ...Object.values(PublicRoutes)].map((el) => el.internal)?.flat();

  // const { isAuthenticated, userInfo } = useUserStore((userState) => pick(userState, ['isAuthenticated', 'userInfo']));
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // const subscriptionLeft = moment(userInfo?.subscription?.startDate).add(userInfo?.subscription?.duration, 'months');
  // const isLeftSubscription = moment(subscriptionLeft).isBefore(moment());

  useEffect(() => {
    setIsMobile(windowWidth.width <= 767);
  }, [windowWidth.width]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [history?.location?.pathname]);

  return isMobile ? (
    <SideNav
      homeRoute={ROUTES.Hotels}
      isMobile={isMobile}
      routes={flatten(allRoutes)}
      notFoundComponent={NotFound}
      footer={() => <Footer />}>
      <MenuItem url={ROUTES.Hotels} label={getText('hotels')} component={HotelsHome} />
      <MenuItem url={ROUTES.Benefits} label={getText('benefits')} component={() => <div>Hello, from Benefits</div>} />
      <MenuItem url={ROUTES.Contact} label={getText('contact')} component={() => <div>Hello, from Contact</div>} />
      <MenuItem
        externalComponent={
          <Button
            type="default"
            small
            onClick={() => {}}
            leftIcon={<Icon iconName="download" className="download-icon" />}
            css={mobileActionButton}>
            {getText('getApp')}
          </Button>
        }
      />
      <MenuItem externalComponent={<div css={horizontalLine} />} />
      <MenuItem externalComponent={<Localization isMobile={isMobile} className="languages-container" />} />

      {/*
  //   REMOVE TAG HIDE FOR FIRST VERSION
  //   <MenuItem
  //     url={ROUTES.Subscriptions}
  //     icon={subscriptionIcon}
  //     label={getText('subscriptions')}
  //     component={SubscriptionPage}
  //   /> */}
    </SideNav>
  ) : (
    <main css={mainContainer}>
      <NavBar />
      <section css={contentContainer}>
        <Switch>
          <Redirect exact from="/" to={ROUTES.Hotels} />
          <Route exact path={ROUTES.Hotels} component={HotelsHome} />
          <Route exact path={ROUTES.HotelsListing} component={HotelsListing} />
          <Route path={ROUTES.HotelDetails} component={HotelDetails} />
          <Route exact path={ROUTES.Benefits} component={() => <div>Hello, from Benefits</div>} />
          <Route exact path={ROUTES.Contact} component={() => <div>Hello, from Contacts</div>} />
          <Route exact path={ROUTES.PrivacyPolicy} component={PrivacyPolicy} />
          <Route exact path={ROUTES.TermsAndConditions} component={TermsAndConditions} />

          {/* // REMOVE TAG HIDE FOR FIRST VERSION
         isAuthenticated && <Route exact path={ROUTES.BuySubscription} component={BuySubscription} />
         isAuthenticated && <Route exact path={ROUTES.SuccessCancelBooking} component={SuccessCancelBooking} /> */}

          {isAuthenticated && <Route exact path={ROUTES.HotelCheckout} component={HotelCheckout} />}
          {isAuthenticated && <Route path={ROUTES.SuccessCheckout} component={SuccessCheckout} />}
          {isAuthenticated && <Route exact path={ROUTES.MyProfile} component={MyProfile} />}
          {isAuthenticated && <Route path={ROUTES.MyBookingsDetails} component={MyBookingsDetails} />}
          {isAuthenticated && <Route path={ROUTES.MyBookings} component={MyBookings} />}
          {isAuthenticated && <Route exact path={ROUTES.Transactions} component={TransactionsPage} />}
          {isAuthenticated && <Route exact path={ROUTES.TransactionDetails} component={TransactionDetails} />}

          <Route component={NotFound} />
        </Switch>
      </section>
      <Footer />
    </main>
  );
};
