import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { isFunction } from 'lodash-es';
import DropdownSelect from './DropdownSelect';
import OptionsList from './OptionsList';
import { useDropDown } from './useDropDown';
import InputContainer from '../InputContainer';
import { minimumWidth } from './utils';
import { dropDownContainer } from './styles';

const Dropdown = (props) => {
  const { required, validate, hasError: hasErrorProp, offset, placement = 'auto', className, dataId } = props;

  const hookProperties = useDropDown(props);

  const { handleToggle, handleKeyDown, handleBlur, error, isTouched, selectRef, setSelectRef, optionsListRef } =
    hookProperties;

  const { styles, attributes } = usePopper(selectRef, optionsListRef, {
    modifiers: [
      minimumWidth,
      { name: 'flip', options: { allowedAutoPlacements: ['bottom', 'top'], rootBoundary: window } },
      { name: 'offset', options: { offset: offset ?? [0, 0] } },
    ].filter(Boolean),
    placement,
  });

  const hasError = isTouched && !!error;
  const hasValidation = isFunction(validate) || required;

  return (
    <InputContainer {...props} dataId={`${dataId}-dropdown-input-container`} error={error} isTouched={isTouched}>
      <div
        ref={setSelectRef}
        role="listbox"
        tabIndex={0}
        data-id={`${dataId}-dropdown-container`}
        onBlur={handleBlur}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        css={dropDownContainer(hasValidation, props)}
        {...(className && { className })}>
        <DropdownSelect
          {...props}
          {...hookProperties}
          hasError={hasError || hasErrorProp}
          dataId={`${dataId}-dropdown-select`}
        />
        <OptionsList
          {...props}
          {...hookProperties}
          popperStyle={styles.popper}
          popperAttributes={attributes.popper}
          dataId={`${dataId}-dropdown-options-list`}
        />
      </div>
    </InputContainer>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  horizontal: PropTypes.bool,
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  options: PropTypes.array,
  small: PropTypes.bool,
  onTop: PropTypes.bool,
  onLeft: PropTypes.bool,
  noClear: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  withSearch: PropTypes.bool,
  noSimplify: PropTypes.bool,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  hasError: PropTypes.bool,
  offset: PropTypes.array,
  placement: PropTypes.string,
  strategy: PropTypes.string,
  dataId: PropTypes.string,
};

const DropdownRef = forwardRef((props, ref) => <Dropdown {...props} dropdownRef={ref} />);

DropdownRef.displayName = 'Dropdown';

export default DropdownRef;
