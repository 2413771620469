import { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { pick, upperCase } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Dropdown, Modal } from 'components/ui';
import { DEFAULT_CURRENCY } from 'enums';
import { modalContainer } from './styles';

const LocalizationModal = forwardRef((props, ref) => {
  const { dataId } = props;
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();
  const { selectedCurrency, fiatCurrencies } = useCurrencies((currenciesState) =>
    pick(currenciesState, ['selectedCurrency', 'fiatCurrencies']),
  );
  const [settings, setSettings] = useState({ currency: null, language: null });
  const modalRef = useRef();

  const languagesOptions = allEnvLanguages?.map((el) => ({
    ...el,
    label: `${upperCase(el.code)} - ${el.name}`,
  }));
  const currenciesOptions = fiatCurrencies?.map((el) => ({
    ...el,
    label: `${el.code} - ${el.name}`,
  }));
  const defaultCurrencyValue = currenciesOptions?.find((el) => el.code === DEFAULT_CURRENCY?.code);

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleChangeSettings = () => {
    settings?.language && changeLanguage(settings?.language?.code);
    settings?.currency && changeUserCurrency(settings?.currency?.code);
    modalRef.current.close();
  };

  return (
    <Modal ref={modalRef} closeIcon css={modalContainer} dataId={`${dataId}-localization-modal-container`}>
      <h3 className="localization-title" data-id={`${dataId}-localization-modal-title`}>
        {getText('displaySettings')}
      </h3>
      <p className="localization-description" data-id={`${dataId}-localization-modal-description`}>
        {getText('changeLanguageAndCurrencyForWholeWebsite')}
      </p>
      <div data-id={`${dataId}-localization-modal-language-dropdowns-container`} className="dropdowns-container">
        <Dropdown
          noClear
          className="localization-dropdown"
          dataId={`${dataId}-localization-modal-language-dropdown`}
          label={getText('language')}
          value={`${upperCase(language?.code)} - ${language?.name}`}
          options={languagesOptions}
          displayKey="label"
          onChange={(value) => setSettings((prev) => ({ ...prev, language: value }))}
        />
        <Dropdown
          noClear
          className="localization-dropdown"
          dataId={`${dataId}-localization-modal-currency-dropdown`}
          label={getText('currency')}
          value={`${selectedCurrency?.code} - ${selectedCurrency?.name}` ?? defaultCurrencyValue?.label}
          options={currenciesOptions}
          displayKey="label"
          onChange={(value) => setSettings((prev) => ({ ...prev, currency: value }))}
        />
      </div>
      <Button dataId={`${dataId}-localization-modal-save-button`} onClick={handleChangeSettings}>
        {getText('saveChanges')}
      </Button>
    </Modal>
  );
});

LocalizationModal.propTypes = {
  dataId: PropTypes.string,
};

export default LocalizationModal;
