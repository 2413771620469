import PropTypes from 'prop-types';
import { isNil, isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import BodyOverflow from '../../BodyOverflow';
import { CheckButton } from '../../Button';
import Input from '../Input';
import { Spinner } from '../../Loader';
import useOptions from './useOptions';
import { DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY } from './utils';
import {
  dropdownSearch,
  infiniteScroll,
  dropDownOptionsContainer,
  dropDownClearAll,
  dropDownOptionItem,
  dropDownGroupLabel,
  noResultsContainer,
} from './styles';

const OptionsList = (props) => {
  const {
    withSearch,
    isOpen,
    small,
    popperStyle,
    popperAttributes,
    optionsListRef,
    setOptionsListRef,
    multiSelect,
    selected,
    handleSelect,
    displayKey = DEFAULT_DISPLAY_KEY,
    uniqueKey = DEFAULT_UNIQUE_KEY,
    renderOption,
    dataId,
  } = props;
  const { getText } = useTranslations();
  const haveClearSelection = multiSelect && !!selected?.length;
  const placement = optionsListRef?.getAttribute('data-popper-placement');
  const getOptionCode = (val) => (isNil(val) ? null : val[uniqueKey] ?? val[displayKey]);
  const selectedCodes = multiSelect ? selected.map(getOptionCode) : getOptionCode(selected);
  const isSelectedOption = (option) => {
    const optionCode = getOptionCode(option);
    return multiSelect ? selectedCodes.indexOf(optionCode) !== -1 : optionCode === selectedCodes;
  };

  const {
    allOptions,
    filterResults,
    searchInputNodeRef,
    optionsScrollContainerRef,
    optionsLoadingNodeRef,
    hasNextPage,
  } = useOptions(props);

  if (!isOpen) return null;

  const renderBaseOptionElement = (option, ind) => {
    const hasRender = isFunction(renderOption);
    const isSelected = isSelectedOption(option);
    const optionsStyles = dropDownOptionItem(!multiSelect && isSelected, small);

    return option.groupLabel ? (
      <h2 key={`item${ind}`} css={dropDownGroupLabel} data-id={`${dataId}-dropdown-options-list-group-label`}>
        {option.groupLabel}
      </h2>
    ) : (
      <div
        key={`item${ind}`}
        role="menuitem"
        data-id={`${dataId}-dropdown-options-list-container`}
        tabIndex={0}
        onClick={() => handleSelect(option)}
        css={optionsStyles}>
        {hasRender ? renderOption(option, optionsStyles) : option[displayKey]}
        {multiSelect && (
          <CheckButton
            value={isSelected}
            size={20}
            dataId={`${dataId}-dropdown-options-list-multiselect-check-button`}
          />
        )}
      </div>
    );
  };

  const renderList = () => {
    const shouldRenderCollapse = multiSelect && !!selected?.length;
    if (!shouldRenderCollapse) return allOptions.map(renderBaseOptionElement);

    const selectedItems = [],
      unselectedItems = [];
    allOptions.forEach((option, ind) => {
      const isSelected = isSelectedOption(option);
      const renderedOption = renderBaseOptionElement(option, ind);
      isSelected ? selectedItems.push(renderedOption) : unselectedItems.push(renderedOption);
    });

    return (
      <>
        {selectedItems}
        {unselectedItems}
      </>
    );
  };

  return (
    <BodyOverflow
      className="dropdown-options-overflow"
      dataId={`${dataId}-dropdown-options-overflow`}
      ref={setOptionsListRef}
      style={popperStyle}
      zIndex={3000}
      {...popperAttributes}>
      <div
        ref={optionsScrollContainerRef}
        role="presentation"
        data-id={`${dataId}-dropdown-options-overflow-scroll-container`}
        onClick={(event) => event.stopPropagation()}
        css={dropDownOptionsContainer(placement)}>
        {withSearch && (
          <Input
            small
            inputRef={searchInputNodeRef}
            placeholder={getText('search')}
            leftIcon={{ material: true, iconName: 'search' }}
            dataId={`${dataId}-dropdown-options-overflow-search-input`}
            onChange={filterResults}
            css={dropdownSearch}
          />
        )}
        {allOptions?.length ? (
          renderList()
        ) : (
          <div css={noResultsContainer} data-id={`${dataId}-dropdown-options-overflow-no-results-container`}>
            <h5 data-id={`${dataId}-dropdown-options-overflow-no-results-title`}>{getText('noResultsFound')}</h5>
          </div>
        )}
        {hasNextPage && (
          <div
            ref={optionsLoadingNodeRef}
            css={infiniteScroll}
            data-id={`${dataId}-dropdown-options-overflow-infinite-scroll`}>
            <Spinner size={65} loading dataId={`${dataId}-dropdown-options-overflow-infinite-scroll-spinner`} />
          </div>
        )}
        {haveClearSelection && (
          <div
            css={dropDownClearAll(props)}
            tabIndex={0}
            role="menuitem"
            data-id={`${dataId}-dropdown-options-overflow-clear-section`}
            onClick={() => handleSelect(null)}>
            {getText('clearAll')}
          </div>
        )}
      </div>
    </BodyOverflow>
  );
};

OptionsList.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  small: PropTypes.bool,
  optionsListRef: PropTypes.any,
  setOptionsListRef: PropTypes.func,
  popperStyle: PropTypes.object,
  popperAttributes: PropTypes.object,
  handleSelect: PropTypes.func,
  selectRef: PropTypes.any,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  renderOption: PropTypes.func,
  dataId: PropTypes.string,
};

export default OptionsList;
