import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslations } from '@veraio/strank';
import { forgotPassword } from '@oneecosystem/authenticate';
import { Button, Form, Icon, Image, Input, inputValidation } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { CAROUSED_IMAGES, CAROUSEL_RESPONSIVE_DATA } from './config';
import { forgotPasswordContainer } from './styles';

const ForgotPassword = ({ history }) => {
  const { getText } = useTranslations();

  return (
    <div css={forgotPasswordContainer} data-id="forgot-password-page-container">
      <Logo className="logo" data-id="forgot-password-page-logo" onClick={() => history.push(ROUTES.Hotels)} />

      <Carousel
        infinite
        pauseOnHover
        showDots
        autoPlay
        rewindWithAnimation
        arrows={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        autoPlaySpeed={3000}
        transitionDuration={3000}
        customTransition="all .5"
        containerClass="carousel-slider"
        dotListClass="dot-list-style"
        responsive={CAROUSEL_RESPONSIVE_DATA}>
        {CAROUSED_IMAGES?.map((el, ind) => (
          <Image key={ind} src={el} className="carousel-image" dataId={`forgot-password-page-carousel-image-${ind}`} />
        ))}
      </Carousel>

      <Form
        className="form-container"
        dataId="forgot-password-form-container"
        onSubmit={() => history.push(forgotPassword())}>
        <h3 className="title" data-id="forgot-password-form-title">
          {getText('forgotPassword')}
        </h3>
        <Input
          required
          formId="email"
          margin="0 0 1em"
          placeholder="name@example.com"
          label={getText('emailAddress')}
          dataId="forgot-password-form-email-input"
          validate={inputValidation('required', 'email')}
        />

        <div className="action-container" data-id="forgot-password-form-action-container">
          {({ onSubmit }) => (
            <Button
              small
              linkTo={forgotPassword()}
              className="recovery-button"
              dataId="forgot-password-form-recovery-button"
              onClick={onSubmit}>
              {getText('getRecoveryCode')}
            </Button>
          )}
        </div>

        <div className="back-container" data-id="forgot-password-form-back-container">
          <Button
            type="link"
            linkTo={ROUTES.Login}
            className="back-button"
            dataId="forgot-password-form-back-to-login-button"
            leftIcon={
              <Icon
                iconName="chevron_left"
                color="gray400"
                className="left-chevron-icon"
                dataId="forgot-password-form-back-icon"
              />
            }>
            {getText('backToLogin')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
