import { theme } from 'styles';

export const navBarHeaderContainer = {
  height: 64,
  backgroundColor: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 2em',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,

  '.logo': {
    height: 38,
    width: 'auto',
  },

  '.buttons-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.nav-button': {
      fontWeight: 500,
      color: theme.black,
      fontSize: 16,
      padding: '0 1.5em',
    },

    '.nav-mobile-button': {
      fontWeight: 500,
      fontSize: 16,
      padding: '0 1.5em',

      '.download-icon': {
        marginRight: '0.3em',
        color: theme.gray700,
      },
    },
  },

  '.right-container': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',

    '.login-styles': {
      padding: '8px 16px',
    },

    '.languages-container': {
      top: 66,
      left: '70%',
    },

    '& > *': {
      marginRight: 16,
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
};
