import { theme } from 'styles';

export const hotelContainer = {
  '.subscription-banner': {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },

  '.background-photo': {
    position: 'relative',
    height: '50rem',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '13rem',

    '@media (max-width: 767px)': {
      alignItems: 'flex-start',
    },

    '.text': {
      textAlign: 'center',
      zIndex: 100,
      marginBottom: '13rem',

      '.save-text': {
        fontSize: '5.0rem',
        fontWeight: 500,
        color: theme.blackLight,
      },

      '.subtitle': {
        color: theme.primary,
        fontSize: '5.0rem',
        fontWeight: 500,
      },

      '@media (max-width: 992px)': {
        marginBottom: '6.4rem',
        marginTop: 0,
      },

      '@media (max-width: 767px)': {
        marginBottom: '6.4rem',
        marginTop: '3rem',
      },
    },

    '.header-logo': {
      height: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },

  '.title': {
    textAlign: 'center',
    color: theme.gray950,
    fontWeight: 500,
    fontSize: '2.2rem',
    marginBottom: '4.8rem',

    '.primary-text': {
      color: theme.primary,
    },

    '@media (max-width: 767px)': {
      padding: '0 8rem',
      marginBottom: '2rem',
    },

    '@media (max-width: 576px)': {
      padding: '0 3rem',
      marginBottom: '1rem',
    },
  },

  '.benefits-container': {
    width: '100%',
    padding: '0 17rem',
    marginBottom: '6.4rem',

    '.image': {
      width: '10rem',
      height: '10rem',
      marginBottom: '1.6rem',

      '@media (max-width: 576px)': {
        width: '14rem',
        height: '14rem',
      },
    },

    '.benefits-title': {
      marginBottom: '1rem',
      fontSize: '1.7rem',
      fontWeight: 500,
    },

    '.benefits-subtitle': {
      marginBottom: '1.6rem',
      color: theme.gray600,
      fontSize: '1.3rem',
      overflow: 'hidden',
      display: ' -webkit-box',
      WebkitLineClamp: 3,
      lineClamp: 3,
      WebkitBoxOrient: 'vertical',
    },

    '@media (max-width: 767px)': {
      padding: '4rem 8rem',
    },

    '@media (max-width: 576px)': {
      padding: '4rem 3rem',
    },
  },

  '.subscription-container': {
    backgroundColor: theme.blueDarker,
    color: theme.white,
    padding: '64px 128px',
    justifyContent: 'stretch',

    '.subscription-subtitle': {
      marginBottom: 32,
    },

    '.column-cart': {
      display: 'flex',
    },
  },

  '.more-container': {
    width: '100%',
    padding: '3rem 17rem',
    marginBottom: '6.4rem',
    backgroundColor: theme.secondaryLightLight,

    '.more-container-title': {
      marginBottom: '1rem',
      fontSize: '2.6rem',
      fontWeight: 500,
      color: theme.gray950,
    },

    '.more-container-description': {
      fontSize: '1.4rem',
      color: theme.gray,
    },

    '.more-column': {
      position: 'relative',

      '.more-image': {
        height: '25.4rem',
        width: '100%',
        borderRadius: 8,
      },

      '.more-info-container': {
        position: 'absolute',
        bottom: '5%',
        left: '5%',
        right: '5%',
        color: theme.white,

        '.more-title': {
          marginBottom: '1rem',
          fontSize: '1.7rem',
          fontWeight: 500,
        },

        '.more-subtitle': {
          fontSize: 13,
          fontWeight: 600,
          overflow: 'hidden',
          display: ' -webkit-box',
          WebkitLineClamp: 3,
          lineClamp: 3,
          WebkitBoxOrient: 'vertical',
          lineHeight: '145%',
        },
      },

      '.coming-soon-container': {
        color: theme.white,
        textTransform: 'uppercase',
        backgroundColor: theme.primary,
        padding: '0.4rem 1.6rem',
        width: 'fit-content',
        position: 'absolute',
        top: 8,
        right: 8,
        borderRadius: '0 8px 0 12px',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3rem 8rem',
    },

    '@media (max-width: 576px)': {
      padding: '3rem',
    },
  },

  '.horizontal-line': {
    margin: '0 17rem',
    marginBottom: '6.4rem',
    backgroundColor: theme.black,

    '@media (max-width: 767px)': {
      margin: '0 8rem',
      marginBottom: '6.4rem',
    },

    '@media (max-width: 576px)': {
      margin: '0 3rem',
      marginBottom: '6.4rem',
    },
  },

  '.rated-container': {
    backgroundColor: theme.primary,
    color: theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6.4rem 12rem',

    '.rated-title': {
      fontWeight: 500,
      fontSize: '2.6rem',
      marginBottom: '2rem',
    },

    '.rated-description': {
      fontSize: '1.3rem',
      width: '52rem',
      lineHeight: '155%',
      textAlign: 'center',
      marginBottom: '4rem',

      '@media (max-width: 992px)': {
        width: '45rem',
      },

      '@media (max-width: 767px)': {
        width: '50rem',
      },
    },

    '.rated-row': {
      display: 'flex',
      justifyContent: 'center',

      '.rated-column': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        backgroundColor: theme.white,
        color: theme.gray900,
        padding: '1.2rem 0.8rem',
        borderRadius: 50,
        margin: '1rem',

        '.rated-user-image': {
          width: '3.2rem',
          height: '3.2rem',
          marginRight: '0.7rem',
        },

        '.rated-reservation-address': {
          fontSize: '1.4rem',
          marginRight: '0.5rem',
        },

        '.rated-stars': {
          display: 'flex',
          flexDirection: 'row',

          '.yellow-star-image': {
            width: '1.4rem',
            height: '1.4rem',
            marginRight: '0.3rem',
          },
        },
      },
    },

    '@media (max-width: 767px)': {
      padding: '6.4rem 5rem',
    },

    '@media (max-width: 576px)': {
      padding: '6.4rem 3rem',
    },
  },

  '.help-container': {
    padding: '8rem 17rem',
    position: 'relative',
    width: '100%',

    '.help-image': {
      height: '50rem',
      borderRadius: 12,
    },

    '.help-info-container': {
      maxWidth: '32.8rem',
      padding: '2.4rem',
      position: 'absolute',
      top: 120,
      right: 220,
      backgroundColor: theme.white,
      borderRadius: 12,

      '.help-title': {
        marginBottom: '1rem',
        fontSize: '2.6rem',
        fontWeight: 500,
        color: theme.gray950,
      },

      '.help-subtitle': {
        fontSize: '1.3rem',
        lineHeight: '155%',
        marginBottom: '1.5rem',
      },

      '.help-button': {
        padding: '1.6rem 3.2rem',
      },

      '@media (max-width: 992px)': {
        right: 160,
      },

      '@media (max-width: 767px)': {
        right: 100,
        top: 80,
        maxWidth: '30rem',
      },

      '@media (max-width: 576px)': {
        right: 50,
        top: 60,
        maxWidth: '20rem',
      },
    },

    '@media (max-width: 992px)': {
      padding: '8rem 12rem',
    },

    '@media (max-width: 767px)': {
      padding: '6rem 8rem',
    },

    '@media (max-width: 576px)': {
      padding: '4rem 3rem',
    },
  },
};
