import { keyframes } from '@emotion/react';
import { theme } from 'styles';

export const publicContentContainer = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '.logo': {
    height: 40,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: '45%',
    cursor: 'pointer',
    zIndex: 1000,

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      left: '50%',
    },
  },

  '.carousel-slider': {
    width: '100vw',

    '.carousel-image': {
      width: '100vw',
      height: '100vh',
      opacity: 0.6,
    },

    '.dot-list-style': {
      marginBottom: '3rem',

      '.react-multi-carousel-dot--active': {
        '& > button': {
          backgroundColor: theme.white,
          border: `1px solid ${theme.white}`,
        },
      },

      '& > li > button': {
        border: `1px solid ${theme.gray150}`,
        backgroundColor: theme.gray150,
        width: 12,
        height: 12,
      },
    },
  },

  '.form-container': {
    maxWidth: 500,
    position: 'absolute',
    top: '15%',
    left: '35%',
    backgroundColor: theme.white,
    borderRadius: 16,
    padding: '2.4rem',

    '.title': {
      fontWeight: 500,
      fontSize: '2.6rem',
      color: theme.blackLight,
      textAlign: 'center',
      marginBottom: '2rem',
    },

    '.password-input': {
      position: 'relative',

      '.eye-icon': {
        position: 'absolute',
        top: 20,
        right: 10,
      },
    },

    '.forgot-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',

      '.forgot-button': {
        padding: 0,
        color: theme.gray900,
        marginBottom: 16,
        textTransform: 'uppercase',
        width: 'fit-content',

        '@media (max-width: 460px)': {
          width: '100%',
        },
      },
    },

    '.action-container': {
      display: 'flex',
      marginBottom: 24,

      '.login-button': {
        width: '100%',
        borderRadius: 8,
        padding: 16,
        fontWeight: 600,
        fontSize: 16,
      },
    },

    '.paragraph': {
      color: theme.gray600,
      lineHeight: 1.4,
      marginBottom: 12,
      fontSize: 13,
      width: '100%',

      '.link-button': {
        padding: 0,
        height: 0,
        color: theme.gray600,
      },
    },

    '.last-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '.logo-account-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.logo-project': {
          width: 44,
          height: 41,
          marginRight: '1rem',
        },

        '.no-account-text': {
          fontSize: 13,
          color: theme.gray900,
          marginBottom: '0.5rem',
        },

        '.more-about': {
          fontWeight: 600,
          fontSize: 13,
          color: theme.gray900,
        },

        '@media (max-width: 460px)': {
          marginBottom: '1rem',
          flexWrap: 'wrap',
        },
      },

      '.create-button': {
        '@media (max-width: 460px)': {
          width: '100%',
        },
      },

      '@media (max-width: 460px)': {
        flexWrap: 'wrap',
      },
    },

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      left: '45%',
    },

    '@media (max-width: 992px)': {
      left: '25%',
    },

    '@media (max-width: 767px)': {
      left: '18%',
    },

    '@media (max-width: 667px) and (min-width: 576px)': {
      left: '7%',
    },

    '@media (max-width: 576px)': {
      left: 0,
    },
  },
};

export const forgotPasswordContainer = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '.logo': {
    height: 40,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: '45%',
    cursor: 'pointer',
    zIndex: 1000,

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      left: '50%',
    },
  },

  '.carousel-slider': {
    width: '100vw',

    '.carousel-image': {
      width: '100vw',
      height: '100vh',
      opacity: 0.6,
    },

    '.dot-list-style': {
      marginBottom: '3rem',

      '.react-multi-carousel-dot--active': {
        '& > button': {
          backgroundColor: theme.white,
          border: `1px solid ${theme.white}`,
        },
      },

      '& > li > button': {
        border: `1px solid ${theme.gray150}`,
        backgroundColor: theme.gray150,
        width: 12,
        height: 12,
      },
    },
  },

  '.form-container': {
    maxWidth: 500,
    position: 'absolute',
    top: '35%',
    left: '35%',
    backgroundColor: theme.white,
    borderRadius: 16,
    padding: '2.4rem',

    '.title': {
      fontWeight: 500,
      fontSize: '2.6rem',
      color: theme.blackLight,
      textAlign: 'center',
      marginBottom: '2rem',
    },

    '.action-container': {
      display: 'flex',
      marginBottom: 24,

      '.recovery-button': {
        width: '100%',
        padding: 16,
        fontWeight: 500,
        fontSize: 16,
      },
    },

    '.back-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '.back-button': {
        color: theme.gray950,

        '.left-chevron-icon': {
          marginRight: '0.5rem',
        },
      },
    },
  },

  '@media (max-width: 4000px) and (min-width: 1920px)': {
    left: '45%',
  },

  '@media (max-width: 992px)': {
    left: '25%',
  },

  '@media (max-width: 767px)': {
    left: '18%',
  },

  '@media (max-width: 667px) and (min-width: 576px)': {
    left: '7%',
  },

  '@media (max-width: 576px)': {
    left: 0,
  },
};

const lock = keyframes`
  50% {top:-4%;}
	100% {top:-6%;}
`;

const colors = keyframes`
  50% {transform:scale(1.1);}
  100% {color:${theme.blueDarkLight}}
`;

export const mainContainer = {
  width: '100%',
  height: 'calc(100vh - 40px)',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: `linear-gradient(180deg, ${theme.infoLight} 0%, ${theme.blue} 100%)`,

  'div[role=button]': {
    color: 'rgb(162,163,166)',
  },

  h1: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '1.7s',

    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: theme.primary,
      zIndex: '-1',
      textAlign: 'center',
      animation: `${lock} .2s ease-in-out forwards`,
      animationDelay: '1.5s',
    },
  },

  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '2s',
  },
};
