import { theme } from 'styles';

export const contentContainer = {
  flex: '1 0 0%',
  position: 'relative',
  width: '100%',
};

export const horizontalLine = {
  borderBottom: `1px solid ${theme.gray400}`,
  margin: '8px 0 24px 0',
  width: '100%',
};

export const mobileActionButton = {
  fontWeight: 500,
  fontSize: 16,
  padding: '0 1.5em',
  width: 'fit-content',
  marginBottom: '2rem',

  '.download-icon': {
    marginRight: '0.3em',
    color: theme.gray700,
  },
};
