import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { add } from 'exact-math';
import { capitalize, camelCase, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { convertCurrencies, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Col, ColorBatch, Icon, Button, Image, PriceLabel, Row, useUrlParams } from 'components';
import { HOTEL_ENTERTAINMENT_MAP, DEFAULT_CURRENCY } from 'enums';
import notHaveImages from 'assets/images/not-have-images.png';
import { cardContainer } from './styles';

const MIN_EMPTY_ROOMS = 5;

const HotelCart = (props) => {
  const { dataId, hotelInfo, searchId, queryParams } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const history = useHistory();
  const { setQueryParams } = useUrlParams();
  const hotelAmenities =
    hotelInfo?.highlightedAmenities?.length > 0 ? hotelInfo.highlightedAmenities : hotelInfo?.hotelAmenities;

  const originalPrice =
    hotelInfo?.priceDetails?.markupPrice &&
    add(hotelInfo?.priceDetails?.markupPrice ?? 0, hotelInfo?.priceDetails?.price ?? 0);
  const markupPriceCryptoPrice = convertCurrencies({ fiatCrypto: hotelInfo?.priceDetails?.markupPrice })?.crypto;

  const handleChangePage = () => {
    history.push({
      pathname: `/hotels/${hotelInfo.id}/overview`,
      state: { ...hotelInfo, ...queryParams, searchId, provider: hotelInfo.providerName },
    });
    setQueryParams({ ...queryParams, searchId, provider: hotelInfo.providerName });
  };

  return (
    <Row dataId={`${dataId}-card-container-hotel-info`} css={cardContainer(hotelInfo?.priceDetails?.markupPrice > 0)}>
      <Col dataId={`${dataId}-card-container-hotel-info-hotel-image-section`} lg={3}>
        <Image
          src={hotelInfo?.image?.length ? hotelInfo?.image : notHaveImages}
          alt="hotel-image"
          dataId={`${dataId}-hotel-image`}
          className="hotel-image"
        />
      </Col>
      <Col
        dataId={`${dataId}-card-container-hotel-information-section`}
        displayFlex
        direction="column"
        justify="space-between"
        gap={18}
        md
        lg={6}>
        <div data-id={`${dataId}-property-theme-container`}>
          {!isEmpty(hotelInfo?.propertyThemes) && (
            <div data-id={`${dataId}-description-property-theme-container`} className="info">
              {hotelInfo?.propertyThemes?.slice(0, 2)?.map((el, ind) => (
                <ColorBatch
                  key={ind}
                  withoutStartCase
                  type={HOTEL_ENTERTAINMENT_MAP[el?.id]?.color}
                  dataId={`entertainment-badge-${el?.name}-badge`}
                  className="entertainment-badge">
                  <div data-id={`${dataId}-${el?.name}-badge-container`} className="badge-container">
                    <Icon
                      dataId={`${dataId}-${el?.name}-property-theme-icon`}
                      size={20}
                      iconName={HOTEL_ENTERTAINMENT_MAP[el?.id]?.icon}
                      margin="0 0.4rem 0 0"
                    />
                    <p data-id={`${dataId}-${el?.name}-property-theme-value`}>
                      {getText(HOTEL_ENTERTAINMENT_MAP[el?.id]?.text)}
                    </p>
                  </div>
                </ColorBatch>
              ))}
            </div>
          )}
          {hotelInfo?.name && <h4 data-id={`${dataId}-name`}>{hotelInfo?.name}</h4>}
          <div data-id={`${dataId}-information-container`} className="hotel-info">
            <div data-id={`${dataId}-information-hotel-location-container`} className="location">
              {hotelInfo?.address?.address && (
                <span data-id={`${dataId}-information-hotel-location-address-name`}>{`${capitalize(
                  hotelInfo?.address?.address,
                )}`}</span>
              )}
              {hotelInfo?.address?.cityName && (
                <span data-id={`${dataId}-information-hotel-location-city-name`}>{`, ${capitalize(
                  hotelInfo?.address?.cityName,
                )}`}</span>
              )}
              {hotelInfo?.address?.countryName && (
                <span data-id={`${dataId}-information-hotel-location-country-name`}>{`, ${capitalize(
                  hotelInfo?.address?.countryName,
                )}`}</span>
              )}
            </div>
            {!!hotelInfo?.reviewRating && (
              <div data-id={`${dataId}-information-hotel-review-rating-container`} className="rating-stars">
                <Image
                  dataId={`${dataId}-information-hotel-review-rating-image-stars`}
                  className="review-rating-icon"
                  src={'https://oneecosystem.blob.core.windows.net/voyage/apps/images/star-icon.svg'}
                />
                <p data-id={`${dataId}-information-hotel-review-rating`} className="review-text">
                  {hotelInfo?.reviewRating}
                </p>
              </div>
            )}
            {!!hotelInfo?.starRating && (
              <div data-id={`${dataId}-information-hotel-star-rating-container`} className="rating-stars">
                <Icon dataId={`${dataId}-information-hotel-star-rating-icon`} iconName="star_rate" size={24} />
                <span data-id={`${dataId}-information-hotel-star-rating-text`} className="star-text">
                  {`${hotelInfo?.starRating} ${getText('starHotel')}`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div data-id={`${dataId}-information-hotel-amenities-container`} className="amenities">
          {hotelAmenities?.slice(0, 3)?.map((el, ind) => (
            <div key={ind} data-id={`${dataId}-information-hotel-${el.name}-amenity`} className="amenities">
              <Icon
                data-id={`${dataId}-information-hotel-${el.name}-amenity-icon`}
                className="amenity-icon"
                size={24}
                iconName={el?.icon ?? 'check_small'}
                color={el?.icon ? null : 'primary'}
              />
              <p data-id={`${dataId}-information-hotel-${el.name}-amenity-name`} className="amenities-info">
                {getText(camelCase(el?.name))}
              </p>
            </div>
          ))}
        </div>
      </Col>
      <Col
        dataId={`${dataId}-information-hotel-price-section`}
        displayFlex
        direction="column"
        justify="space-between"
        gap={18}
        md
        lg={3}>
        <div data-id={`${dataId}-information-hotel-price-container`} className="price-container">
          {hotelInfo?.priceDetails?.savingPercentage > 0 && (
            <ColorBatch
              withoutStartCase
              dataId={`${dataId}-information-hotel-price-saving-percentage-batch`}
              type="error">
              {hotelInfo?.priceDetails?.savingPercentage}% {getText('off')}
            </ColorBatch>
          )}
          {hotelInfo?.roomLeft > 0 && hotelInfo?.roomLeft <= MIN_EMPTY_ROOMS && (
            <ColorBatch withoutStartCase dataId={`${dataId}-information-hotel-price-only-few-left-batch`} type="error">
              {getText('onlyFewLeftAt')}
            </ColorBatch>
          )}
          <div data-id={`${dataId}-information-hotel-price`} className="price">
            <p data-id={`${dataId}-information-hotel-price-total`} className="total-price">
              {getText('total')}
            </p>
            <div data-id={`${dataId}-information-hotel-price-value-section`} className="original-price-row">
              <p data-id={`${dataId}-information-hotel-original-price`} className="original-price">
                <PriceLabel
                  fiat={originalPrice}
                  fiatOriginCurrency={DEFAULT_CURRENCY.code}
                  fiatDesiredCurrency={selectedCurrency?.code}
                  fiatOnly={false}
                  dataId={`${dataId}-information-hotel-original-price-value`}
                />
              </p>
              {hotelInfo?.priceDetails?.price > 0 && (
                <h4 data-id={`${dataId}-information-hotel-discounted-price`} className="discounted-price">
                  <PriceLabel
                    fiat={Number(hotelInfo?.priceDetails?.price)}
                    crypto={markupPriceCryptoPrice}
                    fiatOriginCurrency={DEFAULT_CURRENCY.code}
                    fiatDesiredCurrency={selectedCurrency?.code}
                    fiatOnly={false}
                    dataId={`${dataId}-information-hotel-discounted-price-value`}
                  />
                </h4>
              )}
            </div>
          </div>
        </div>
        <Button
          className="availability-button"
          dataId={`${dataId}-information-hotel-availability-button`}
          rightIcon={{ iconName: 'keyboard_arrow_right' }}
          onClick={handleChangePage}>
          {getText('checkAvailability')}
        </Button>
      </Col>
    </Row>
  );
};

HotelCart.propTypes = {
  dataId: PropTypes.string,
  hotelInfo: PropTypes.object,
  searchId: PropTypes.string,
  queryParams: PropTypes.object,
};

export default HotelCart;
