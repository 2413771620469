import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components/ui';
import { ratingReviewStarsContainer } from './styles';

const RatingReviewStars = (props) => {
  const { hotel, withoutStars } = props;
  const { getText } = useTranslations();

  return (
    <div css={ratingReviewStarsContainer}>
      {hotel?.reviewRating > 0 && <h5 className="guest-rating">{hotel?.reviewRating}</h5>}
      {hotel?.reviewRating > 0 && <div className="dot" />}
      {(hotel?.reviewRatingCount > 0 || hotel?.reviewCount > 0) && (
        <p className="reviews">{`${hotel?.reviewRatingCount ?? hotel?.reviewCount} ${getText('reviews')}`}</p>
      )}
      {hotel?.starRating !== 0 && hotel?.hotelStarRating !== 0 && !withoutStars && (
        <div className="hotel-stars">
          <Icon size={24} material iconName="star_rate" className="icon" />{' '}
          <p>{`${hotel?.starRating ?? hotel?.hotelStarRating} ${getText('starHotel')}`}</p>
        </div>
      )}
    </div>
  );
};

RatingReviewStars.propTypes = {
  hotel: PropTypes.object,
  withoutStars: PropTypes.bool,
};

export default RatingReviewStars;
