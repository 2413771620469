import { theme } from 'styles';

export const cardContainer = {
  margin: '1.6rem',
  height: 250,
  border: `1px solid ${theme.gray150}`,
  borderRadius: 12,

  '.hotel-image': {
    borderRadius: '10px 0 0 10px',
    maxHeight: 300,
    height: '100%',
    aspectRatio: '4 / 3',

    '@media (max-width: 992px)': {
      borderRadius: '10px 10px 0 0',
    },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.info': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.6rem',
    marginBottom: '1rem',

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.margin': {
    marginBottom: 8,

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.location': {
    color: theme.gray300,
    margin: '1.2rem 1rem 1.2rem 0',
    fontSize: '1.6rem',

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.amenities': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: theme.gray800,
    margin: '0.4rem 0',

    '@media (max-width: 768px)': {
      marginTop: '0.8rem',
    },
  },

  '.price-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',

    '.price': {
      display: 'flex',
      flexDirection: 'column',

      '@media (max-width: 992px)': {
        alignItems: 'flex-start',
      },
    },

    '@media (max-width: 992px)': {
      alignItems: 'flex-start',
      marginTop: '0.4rem',
    },
  },

  '.availability-button': {
    padding: '0.8rem 3.2rem ',
    borderRadius: 0,
    alignSelf: 'flex-end',
    color: theme.white,

    '@media (max-width: 992px)': {
      marginTop: '1rem',
      padding: '0 1.8rem ',
      maxWidth: '100%',
      textWrap: 'wrap',
      alignSelf: 'flex-start',
    },
  },

  '@media (max-width: 992px)': {
    height: 'auto',
  },
};
