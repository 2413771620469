import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useNavigationControls } from '../NavigationContext';
import { sideNavContent } from './styles';

const Content = ({ notFoundComponent, homeRoute, isMobile }) => {
  const { allRoutes } = useNavigationControls();
  const [routesAndRedirects, setRoutesAndRedirects] = useState({
    routes: [],
    redirects: [],
  });

  useEffect(() => {
    const routes = [];
    const redirects = [];
    allRoutes.forEach((el) => {
      if (el?.component && el?.url) routes.push(el);
      if (el?.redirectsTo && el?.url) redirects.push(el);
    });
    setRoutesAndRedirects({ routes, redirects });
  }, [allRoutes]);

  return (
    <article css={sideNavContent(isMobile)} data-id="navigation-content-article">
      {!!routesAndRedirects?.routes?.length && (
        <Switch>
          {homeRoute && <Redirect exact from="/" to={homeRoute} />}
          {routesAndRedirects.routes?.map((route, i) => (
            <Route key={`${route.url}-${i}`} exact={route.exact} path={route.url} component={route.component} />
          ))}
          {routesAndRedirects.redirects?.map((el) => (
            <Redirect key={el.url} exact from={el.url} to={el.redirectsTo} />
          ))}
          {notFoundComponent && <Route component={notFoundComponent} />}
        </Switch>
      )}
    </article>
  );
};

Content.propTypes = {
  notFoundComponent: PropTypes.any,
  homeRoute: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Content;
