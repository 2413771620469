import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { initCurrenciesStore } from '@oneecosystem/dealshaker-core';
import { NavigationProvider, Notifications, useDeepEffect } from 'components';
import { ROUTES } from 'enums';
import { initCountries, useUserStore } from 'stores';
import { AuthorizationCallback, ForgotPassword, Login, Logout } from './authorization';
import { Routes } from './Routes';

const App = () => {
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  const { changeLanguage, allEnvLanguages } = useTranslations();

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useEffect(() => {
    initTranslationsStore();
    initCountries();
    initLanguagesStore();
    initCurrenciesStore();
  }, []);

  return (
    <NavigationProvider>
      <Notifications />

      <Switch>
        <Route exact path={ROUTES.Logout} component={Logout} />
        <Route exact path={ROUTES.AuthorizationCallback} component={AuthorizationCallback} />
        {!isAuthenticated && <Route exact path={ROUTES.Login} component={Login} />}
        {!isAuthenticated && <Route exact path={ROUTES.ForgotPassword} component={ForgotPassword} />}
        <Route component={Routes} />
      </Switch>
    </NavigationProvider>
  );
};

export default App;
