import { theme } from 'styles';

export const inputField = (hasValidation, hasError, { disabled, small, leftIcon }) => [
  {
    color: theme.gray700,
    border: 'none',
    borderBottom: `1px solid ${hasError ? theme.gray100 : theme.gray200}`,
    backgroundColor: theme.white,
    minHeight: small ? '3.6rem' : '4.8rem',
    padding: small ? '0.35em 0.85em' : '0.7em 0em',
    width: '100%',
    marginBottom: hasValidation ? 6 : 3,
    outline: 'none',
    paddingTop: '3rem',

    '&::placeholder': {
      fontSize: 13,
    },
  },
  hasError && {
    '&::placeholder': {
      color: theme.gray500,
    },
  },
  leftIcon && {
    paddingLeft: `${(leftIcon?.size ?? 2) + 0.6}em`,
  },
  disabled
    ? {
        borderColor: theme.gray150,
        color: theme.gray200,
        backgroundColor: theme.whiteGray,
        cursor: 'not-allowed',

        '&::placeholder': {
          color: theme.gray150,
        },
      }
    : {
        '&:focus': {
          borderColor: theme.secondaryDark,
          outline: 'none',

          '&::placeholder': {
            color: theme.gray500,
          },
        },
      },
];
