import { useState, useRef } from 'react';
import { camelCase, isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, FeaturesTypeCart, showApiError, BookingCart, Pagination, Spinner } from 'components';
import { getMyBookings } from 'services';
import { ReactComponent as NotHaveBookings } from 'assets/images/not-have.svg';
import { bookingsTabsContentContainer } from './styles';

const BookingsTabsContent = ({ bookingStatus }) => {
  const { getText } = useTranslations();
  const [myBooking, setMyBooking] = useState({});
  const loaderRef = useRef();

  const fetchBookingContent = async (options) => {
    loaderRef.current?.showLoading();

    const [res, err] = await getMyBookings({
      status: isArray(bookingStatus) ? bookingStatus?.map((el) => camelCase(el)) : camelCase(bookingStatus),
      ...options,
    });

    if (err) return showApiError(err);
    loaderRef.current?.hideLoading();
    setMyBooking(res);
    return res;
  };

  return (
    <div css={bookingsTabsContentContainer}>
      <Row gap={16}>
        <Col lg={4} md={12}>
          <FeaturesTypeCart
            iconName="corporate_fare"
            title={getText('hotelBookings')}
            length={myBooking?.totalResults ?? 0}
            type="hotels"
          />
        </Col>
        <Col lg={8} md={12}>
          <div>
            {myBooking?.results?.length > 0 ? (
              myBooking?.results?.map((el, ind) => (
                <BookingCart key={ind} bookingInfo={el} bookingStatus={bookingStatus} />
              ))
            ) : (
              <div className="not-have-container">
                <NotHaveBookings />
                <h4 className="not-have-title">{getText('notHaveBookings')}</h4>
              </div>
            )}
            <Pagination onChange={fetchBookingContent} pageSize={7} justify="center" />
          </div>
          <Spinner ref={loaderRef} text={getText('loadingRooms')} />
        </Col>
      </Row>
    </div>
  );
};

export default BookingsTabsContent;
