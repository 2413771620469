import { theme } from 'styles';
export const footerContainer = {
  padding: '3rem 12rem 1rem',
  backgroundColor: theme.gray50,

  '.first-container': {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0 2rem',

    '.logo': {
      height: 38,
      width: 'auto',
    },

    '.footer-title': {
      fontWeight: 600,
      fontSize: 13,
      color: theme.gray900,
      marginBottom: '1.6rem',
    },

    '.apps-container': {
      position: 'relative',

      '.icon': {
        height: 50,
        width: 140,
      },

      '.app-store-icon': {
        objectFit: 'contain',
        height: 50,
        width: 140,
        marginBottom: '0.4rem',
      },
    },

    '.lang-currency-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',

      '.dropdown': {
        width: 145,

        '.select-dropdown': {
          borderRadius: 4,
        },
      },

      '@media (max-width: 576px)': {
        alignItems: 'flex-start',
      },
    },
  },

  '.second-container': {
    fontSize: 11,
    height: 40,
    zIndex: 0,
    borderTop: `1px solid ${theme.gray150}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '.text': {
      color: theme.gray900,
      fontWeight: 500,
    },

    '.button': {
      fontWeight: 500,
      color: theme.gray900,
      padding: '0 12px',
      minHeight: 0,

      '.help-button': {
        color: theme.gray900,
      },
    },
  },

  '@media (max-width: 576px)': {
    padding: '3rem 5rem 1rem',
  },
};

export const footerLinkButton = (disabled) => ({
  fontWeight: 400,
  fontSize: 13,
  color: theme.gray900,
  padding: '0.2em 0em',
  opacity: disabled && 0.7,

  '&:active': { outline: 'none' },

  '.soon-container': {
    backgroundColor: theme.white,
    padding: '0.4rem 0.6rem',
    textTransform: 'uppercase',
    marginLeft: '.4em',
    borderRadius: 24,
    zIndex: 100,
    color: theme.black,
  },
});
