import { theme } from 'styles';

export const successfulPaymentContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 124,
  backgroundColor: theme.blueLighter,
  height: 'calc(100vh - 104px)',

  '.hotel-info': {
    marginRight: 48,

    '.title': {
      marginBottom: 12,
    },

    '.subtitle': {
      color: theme.gray400,
      marginBottom: 40,
    },

    '.actions': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  '.image': {
    height: 382,
    width: 314,

    '@media (max-width: 767px)': {
      height: 282,
      width: 214,
    },
  },

  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },
};
