import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from 'components';
import { fadeInAnimation } from '../../../../styles';
import { inputContainer, inputLabel, inputHelpContainer, inputHelpContent, inputHelpIcon } from './styles';

const InputContainer = (props) => {
  const { isTouched, required, error, validate, label, className, children, dataId } = props;
  const hasError = isTouched && !!error;
  const hasValidation = isFunction(validate) || required;

  return (
    <div css={inputContainer(props)} {...(className && { className })} data-id={`${dataId}-input-container`}>
      {!!label && (
        <label css={inputLabel(props)} className="input-label" data-id={`${dataId}-input-container-label`}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}

      {children}

      {hasValidation && (
        <div
          css={inputHelpContainer}
          className="input-help-container"
          data-id={`${dataId}-input-container-validations`}>
          {hasError && (
            <div css={[inputHelpContent, fadeInAnimation()]} data-id={`${dataId}-input-container-error`}>
              <Icon
                material
                iconName="error"
                css={inputHelpIcon}
                size={14}
                dataId={`${dataId}-input-container-error-icon`}
              />
              <span data-id={`${dataId}-input-container-error-message`}>{error.msg}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

InputContainer.propTypes = {
  label: PropTypes.string,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.any,
  validate: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  dataId: PropTypes.string,
};

export default InputContainer;
