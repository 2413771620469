import { theme } from 'styles';

export const localizationContainer = (isMobile) => ({
  width: isMobile && '100%',

  '.content-container': {
    backgroundColor: theme.gray100,
    border: `1px solid ${theme.gray100}`,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
  },

  '.button-container': {
    width: '100%',

    '.icon': {
      marginRight: '0.5rem',
    },
  },
});

export const modalContainer = {
  '.close-icon': {
    top: 30,
  },

  '.localization-title': {
    fontWeight: 500,
    fontSize: '2.2rem',
    marginBottom: '2.5rem',
    color: theme.gray950,
  },

  '.localization-description': {
    fontSize: '1.3rem',
    marginBottom: '0.5rem',
    color: theme.gray600,
  },

  '.dropdowns-container': {
    marginBottom: '3rem',

    '.localization-dropdown': {
      '.input-label': {
        top: 14,
        left: 22,
        fontWeight: 500,
        fontSize: '1.4rem',
        color: theme.blackLight,
      },

      '.select-dropdown': {
        minHeight: '6.4rem',
        borderRadius: 8,
        padding: '0.7em 0.8em 0',

        '.selected-value': {
          fontWeight: 400,
          fontSize: '1.4rem',
          color: theme.blackLight,
        },

        '.expand-icon': {
          fontSize: 24,
        },
      },
    },
  },
};
