import PropTypes from 'prop-types';
import { Col, Row, Skeleton } from 'components';
import { cardContainer } from './styles';

const LoadingHotelCard = ({ dataId }) =>
  Array(10)
    .fill()
    .map((_, i) => (
      <Row key={i} css={cardContainer} dataId={`${dataId}-loading-card-row`}>
        <Col dataId={`${dataId}-loading-card-column`} lg={3}>
          <Skeleton width={300} height={250} className="hotel-image" dataId={`${dataId}-loading-card-hotel-image`} />
        </Col>
        <Col
          dataId={`${dataId}-loading-card-hotel-information-section`}
          displayFlex
          direction="column"
          justify="space-between"
          gap={18}
          md
          lg={6}>
          <div data-id={`${dataId}-loading-card-hotel-information-section`}>
            <Skeleton width={200} height={22} className="info" dataId={`${dataId}-loading-card-hotel-name`} />
            <Skeleton
              width={500}
              height={24}
              className="margin"
              dataId={`${dataId}-loading-card-hotel-property-themes`}
            />
            <Skeleton width={500} height={22} className="location" dataId={`${dataId}-loading-card-hotel-location`} />
          </div>
          <Skeleton width={300} height={22} className="amenities" dataId={`${dataId}-loading-card-hotel-amenities`} />
        </Col>
        <Col
          dataId={`${dataId}-loading-card-hotel-price-section`}
          displayFlex
          direction="column"
          justify="space-between"
          gap={18}
          md
          lg={3}>
          <div data-id={`${dataId}-loading-card-hotel-price-container`} className="price-container">
            <Skeleton width={100} height={24} className="margin" dataId={`${dataId}-loading-card-total-text`} />
            <div data-id={`${dataId}-loading-card-hotel-price`} className="price">
              <Skeleton
                width={30}
                height={24}
                className="margin"
                dataId={`${dataId}-loading-card-total-discounted-price`}
              />
              <Skeleton
                width={200}
                height={24}
                className="margin"
                dataId={`${dataId}-loading-card-total-original-price`}
              />
            </div>
          </div>
          <Skeleton
            width={200}
            height={48}
            className="availability-button"
            dataId={`${dataId}-loading-card-availability-button`}
          />
        </Col>
      </Row>
    ));

LoadingHotelCard.propTypes = {
  dataId: PropTypes.string,
};

export default LoadingHotelCard;
