import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import { Spinner } from '../Loader';
import { buttonContainer } from './styles';

const externalLinkExpression =
  // eslint-disable-next-line max-len
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;

const Button = (props) => {
  const {
    children,
    onClick,
    disabled,
    leftIcon,
    rightIcon,
    className,
    preventDefault,
    stopPropagation,
    linkTo,
    loading = false,
    target = '_blank',
    dataId,
  } = props;
  const loaderRef = useRef();
  const buttonStyles = buttonContainer(props, loaderRef?.isLoading);

  const handleClick = async (e) => {
    if (disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    stopPropagation && e.stopPropagation();
    if (isFunction(onClick)) {
      loading && loaderRef.current.showLoading();
      await onClick(e);
      loading && loaderRef.current.hideLoading();
    }
  };

  return linkTo ? (
    externalLinkExpression.test(linkTo) ? (
      <a
        href={linkTo}
        css={buttonStyles}
        target={target}
        rel="noreferrer"
        {...(className && { className })}
        data-id={`${dataId}-anchor-href`}
        onClick={handleClick}>
        {leftIcon?.iconName ? <Icon {...leftIcon} data-id={`${dataId}-anchor-href-left-icon`} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} data-id={`${dataId}-anchor-href-right-icon`} /> : rightIcon}
      </a>
    ) : (
      <Link
        to={linkTo}
        {...(className && { className })}
        data-id={`${dataId}-link`}
        css={buttonStyles}
        onClick={handleClick}>
        {leftIcon?.iconName ? <Icon {...leftIcon} data-id={`${dataId}-link-left-icon`} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} data-id={`${dataId}-link-right-icon`} /> : rightIcon}
      </Link>
    )
  ) : (
    <div
      role="button"
      tabIndex={0}
      css={buttonStyles}
      {...(className && { className })}
      data-id={`${dataId}-div-button-container`}
      onClick={handleClick}>
      {leftIcon?.iconName ? <Icon {...leftIcon} data-id={`${dataId}-div-button-left-icon`} /> : leftIcon}
      {children}
      {rightIcon?.iconName ? <Icon {...rightIcon} data-id={`${dataId}-div-button-right-icon`} /> : rightIcon}
      <Spinner ref={loaderRef} className="loading-button" dataId={`${dataId}-button-spinner-loader`} />
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['primary', 'secondary', 'default', 'success', 'warning', 'info', 'danger', 'link']),
  outline: PropTypes.bool,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  color: PropTypes.string,
  backColor: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
  dataId: PropTypes.string,
};

export default Button;
