import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash-es';
import ColorBatch from '../../ColorBatch';
import { Icon } from '../../Icon';
import {
  dropDownSelect,
  dropDownRightIcon,
  dropDownText,
  rightIconsContainer,
  dropDownDeleteIcon,
  dropDownItemsCountBadge,
} from './styles';

const DropdownSelect = ({
  leftIcon,
  rightIcon,
  small = false,
  multiSelect = false,
  noClear = false,
  noItemsBadge = false,
  selected,
  handleSelect,
  isOpen,
  isDisabled,
  displayValue,
  hasError,
  dataId,
}) => {
  const clearIcon = !noClear && (
    <Icon
      material
      iconName="close"
      color="black"
      dataId={`${dataId}-dropdown-select-clear-icon`}
      css={dropDownDeleteIcon}
      onClick={(e) => {
        e.stopPropagation();
        handleSelect(null);
      }}
    />
  );

  const itemsBadge = !noItemsBadge && (
    <ColorBatch css={dropDownItemsCountBadge(false)} dataId={`${dataId}-dropdown-select-color-batch-items`}>
      {selected?.length}
    </ColorBatch>
  );

  return (
    <div
      css={dropDownSelect(isOpen, isDisabled, hasError, small)}
      className="select-dropdown"
      data-id={`${dataId}-dropdown-select-container`}>
      {leftIcon?.iconName ? (
        <Icon {...leftIcon} dataId={`${dataId}-dropdown-select-left-icon`} />
      ) : isFunction(leftIcon) ? (
        leftIcon(isOpen)
      ) : (
        leftIcon
      )}
      <span css={dropDownText} className="selected-value" data-id={`${dataId}-dropdown-select-display-value`}>
        {displayValue}
      </span>
      <div css={rightIconsContainer} data-id={`${dataId}-dropdown-select-icons`}>
        {!isEmpty(selected) && (multiSelect ? itemsBadge : clearIcon)}
        <Icon
          material
          iconName="expand_more"
          color="black"
          dataId={`${dataId}-dropdown-select-right-icon`}
          className="expand-icon"
          css={dropDownRightIcon(isOpen)}
          {...rightIcon}
        />
      </div>
    </div>
  );
};

DropdownSelect.propTypes = {
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.object,
  small: PropTypes.bool,
  multiSelect: PropTypes.bool,
  noClear: PropTypes.bool,
  noItemsBadge: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  displayValue: PropTypes.any,
  dataId: PropTypes.string,
};

export default DropdownSelect;
