import PropTypes from 'prop-types';
import { isObjectLike, isFunction } from 'lodash-es';
import { Icon } from '../../Icon';
import { useAutocomplete } from './useAutocomplete';
import {
  searchBarContainer,
  searchBarInputContainer,
  searchBarInput,
  searchBarInputIcon,
  searchBarSuggestionsContainer,
  searchBarSuggestionItem,
} from './styles';

const SearchBar = (props) => {
  const { displayKey = 'label', className, renderInput, renderSuggestionsContainer, renderSuggestion, dataId } = props;
  const { inputProps, suggestionItemProps, isOpen, suggestions, rootNodeRef } = useAutocomplete(props);

  const listOfSuggestions = suggestions?.map((el, i) => {
    const itemProps = {
      css: searchBarSuggestionItem,
      key: `suggestion-${i}`,
      role: 'button',
      tabIndex: 0,
      onBlur: inputProps.onBlur,
      onClick: () => suggestionItemProps.onClick(el),
      onKeyDown: suggestionItemProps.onKeyDown,
      [suggestionItemProps.itemIdAttr]: i,
    };
    return isFunction(renderSuggestion) ? (
      renderSuggestion({ itemProps, suggestionItemProps, item: el })
    ) : (
      <div {...itemProps} data-id={`${dataId}-search-bar-list-of-suggestions`}>
        {isObjectLike(el) ? el[displayKey] : el}
      </div>
    );
  });

  return (
    <div
      ref={rootNodeRef}
      css={searchBarContainer(props)}
      {...(className && { className })}
      data-id={`${dataId}-search-bar-container`}>
      {isFunction(renderInput) ? (
        renderInput({
          containerProps: { css: searchBarInputContainer(props) },
          inputProps: { css: searchBarInput, ...inputProps },
          iconProps: {
            material: true,
            iconName: 'search',
            css: searchBarInputIcon,
          },
        })
      ) : (
        <div css={searchBarInputContainer} data-id={`${dataId}-search-bar-input-container`}>
          <label htmlFor={inputProps.id} data-id={`${dataId}-search-bar-input-label`}>
            <Icon
              material
              iconName="search"
              css={searchBarInputIcon}
              data-id={`${dataId}-search-bar-input-search-icon`}
            />
          </label>
          <input css={searchBarInput} {...inputProps} data-id={`${dataId}-search-bar-input-search-input`} />
        </div>
      )}

      {isFunction(renderSuggestionsContainer)
        ? renderSuggestionsContainer({
            isOpen,
            suggestions,
            css: searchBarSuggestionsContainer,
            children: listOfSuggestions,
          })
        : isOpen && !!suggestions?.length && <div css={searchBarSuggestionsContainer}>{listOfSuggestions}</div>}
    </div>
  );
};

SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  displayKey: PropTypes.string,
  renderInput: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
  dataId: PropTypes.string,
};

export default SearchBar;
