import { useRef, useState } from 'react';
import moment from 'moment';
import { isNil, pick, omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  Icon,
  Pagination,
  showApiError,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Skeleton,
  Spinner,
  useUrlParams,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // AmenitiesSlider,
  HotelCart,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // LeftSubscriptionBanner,
  ListingFilters,
  LoadingHotelCard,
} from 'components';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useUserStore } from 'stores';
import { getAllHotels } from 'services';
import { ReactComponent as NotHaveRooms } from 'assets/images/not-have.svg';
import { container, filtersContainer, scrollableContainer, banner } from './styles';

const filterKeys = {
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  latitude: 'latitude',
  longitude: 'longitude',
  city: 'city',
  country: 'country',
  adults: 'adults',
  rooms: 'rooms',
  children: 'children',
  childrenAges: 'childrenAges',
  propertyThemes: 'propertyThemes',
  rateOptions: 'rateOptions',
  reviewRatings: 'reviewRatings',
  minPrice: 'minPrice',
  maxPrice: 'maxPrice',
  bedsCount: 'bedsCount',
  neighborhoods: 'neighborhoods',
  brands: 'brands',
  starRatings: 'starRatings',
  amenities: 'amenities',
  propertyTypes: 'propertyTypes',
  language: 'language',
  currencyCode: 'currencyCode',
  sortBy: 'sortBy',
  searchId: 'searchId',
  radius: 'radius',
  unit: 'unit',
};

const HotelsListing = () => {
  const { getText } = useTranslations();
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const [data, setData] = useState();
  const { queryParams } = useUrlParams();
  const scrollContainerRef = useRef();
  const containerRef = useRef();
  const loaderRef = useRef();
  const paginationRef = useRef();

  const handleApplyFilters = async (options) => {
    const params = {
      ...queryParams,
      ...options,
      checkIn: moment(queryParams.checkIn).format('MM-D-YYYY') ?? moment(options.checkIn).format('MM-D-YYYY'),
      checkOut: moment(queryParams.checkOut).format('MM-D-YYYY') ?? moment(options.checkOut).format('MM-D-YYYY'),
      ...{ ...(options.pageIndex > 1 && { searchId: data?.searchId ?? null }) },
    };
    loaderRef.current.showLoading();

    const [res, err] = await getAllHotels(omit(params, ['location', 'locationName']));
    if (err) return showApiError(err);

    loaderRef.current?.hideLoading();
    setData(res);
    return res;
  };

  const handleScrollToTop = () =>
    document.getElementById('#header').scrollTo({
      behavior: 'smooth',
      left: 0,
      top: -scrollContainerRef.current?.scrollHeight,
    });

  return (
    <div css={container} data-id="hotel-listing-container">
      <div data-id="hotel-listing-banner-container">
        {/*
         REMOVE TAG HIDE FOR FIRST VERSION
        {isAuthenticated && <LeftSubscriptionBanner show />} */}
        <div css={banner} data-id="hotel-listing-banner" />
      </div>
      <div css={filtersContainer} ref={containerRef} data-id="hotel-listing-filters-container">
        <ListingFilters
          hotels={data?.results}
          filters={data?.filters}
          onApplyFilters={(filters) => paginationRef.current?.applyFilters(filters)}
          dataId="hotel-listing-filter-bar"
        />
      </div>
      <div
        css={scrollableContainer(data?.results?.length > 0)}
        ref={scrollContainerRef}
        id="#header"
        data-id="hotel-listing-scrollable-container">
        {/*
         REMOVE TAG HIDE FOR FIRST VERSION
         {isNil(data?.filters?.amenities) ? (
          <div className="amenity-badge">
            {Array(15)
              .fill()
              .map((_, i) => (
                <Skeleton key={i} width={80} height={30} />
              ))}
          </div>
        ) : (
          <AmenitiesSlider
            hotelAmenities={data?.filters?.amenities}
            onApplyFilters={(filters) => paginationRef.current?.applyFilters(filters)}
            batchClassName="amenities-styles"
          />
        )} */}
        {isNil(data?.results) ? (
          <LoadingHotelCard dataId="hotel-listing-loading-card" />
        ) : data?.results?.length > 0 ? (
          data?.results?.map((el, ind) => (
            <HotelCart
              key={ind}
              dataId={`hotel-listing-${el.name}-cart`}
              hotelInfo={el}
              queryParams={queryParams}
              searchId={data?.searchId}
            />
          ))
        ) : (
          <div className="not-have-container" data-id="hotel-listing-not-have-container">
            <NotHaveRooms data-id="hotel-listing-not-have-image" />
            <h4 className="not-have-title" data-id="hotel-listing-not-have-title">
              {getText('notHaveHotelsByThisFilters')}
            </h4>
            <p className="not-have-subtitle" data-id="hotel-listing-not-have-subtitle">
              {getText('pleaseChooseNewFilters')}
            </p>
          </div>
        )}
        <Pagination
          justify="center"
          pageSize={10}
          ref={paginationRef}
          className="pagination"
          dataId="hotel-listing-pagination"
          requiredFilterKeys={pick(filterKeys, [
            'checkIn',
            'checkOut',
            'latitude',
            'longitude',
            'city',
            'country',
            'adults',
            'rooms',
          ])}
          filterKeys={filterKeys}
          onChange={handleApplyFilters}
        />
        {data?.results?.length > 0 && (
          <div className="to-top-button" data-id="hotel-listing-scroll-to-top-button-container">
            <Button small className="button" dataId="hotel-listing-scroll-to-top-button" onClick={handleScrollToTop}>
              <Icon
                material
                iconName="arrow_upward"
                dataId="hotel-listing-scroll-to-top-button-arrow"
                className="button-to-top-arrow"
              />
            </Button>
            <p className="text" data-id="hotel-listing-scroll-to-top-text">
              {getText('backToTop')}
            </p>
          </div>
        )}
      </div>
      <Spinner ref={loaderRef} text={getText('loadingHotels')} dataId="hotel-listing-spinner" />
    </div>
  );
};

export default HotelsListing;
