import { getReq, postReq, putReq, deleteReq } from 'services';

const baseUrl = `${apiUrls.voyageApiUrl}/api/Profiles`;

export const getOneVoyageProfile = () => getReq(`${baseUrl}/my`);

export const createPassenger = (data) => postReq(`${baseUrl}/passenger`, data);

export const updatePassenger = (passengerId, data) => putReq(`${baseUrl}/passenger/${passengerId}`, data);

export const deletePassenger = (passengerId) => deleteReq(`${baseUrl}/passenger/${passengerId}`);
