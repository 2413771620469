import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash-es';
import { concatStrings } from '../utils';
import { baseIcon } from './styles';

const Icon = (props) => {
  const { onClick, iconName, className, linkTo, material = true, dataId } = props;

  const iconCore = material ? (
    <div
      className={concatStrings('material-symbols-outlined', !linkTo && className)}
      css={baseIcon(props)}
      data-id={`${dataId}-icon-div-container`}
      {...(isFunction(onClick) && {
        onClick,
        tabIndex: 0,
        role: 'button',
      })}>
      {iconName}
    </div>
  ) : (
    <i
      role="presentation"
      className={concatStrings(iconName, !linkTo && className)}
      css={baseIcon(props)}
      data-id={`${dataId}-icon-container`}
      {...(isFunction(onClick) && {
        onClick,
        tabIndex: 0,
        role: 'button',
      })}
    />
  );

  return linkTo ? (
    <Link to={linkTo} {...(className && { className })} data-id={`${dataId}-icon-link-container`}>
      {iconCore}
    </Link>
  ) : (
    iconCore
  );
};

Icon.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  material: PropTypes.bool,
  dataId: PropTypes.string,
};

export default Icon;
