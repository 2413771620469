import { theme } from 'styles';

export const cardContainer = (hasDiscount) => ({
  margin: '1.6rem',
  height: 320,
  border: `1px solid ${theme.gray150}`,
  borderRadius: 12,

  '.hotel-image': {
    borderRadius: '10px 0 0 10px',
    maxHeight: 300,
    height: '100%',
    aspectRatio: '4 / 3',

    '@media (max-width: 992px)': {
      borderRadius: '10px 10px 0 0',
    },
  },

  '.info': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.6rem',

    '.entertainment-badge': {
      borderRadius: 25,
      margin: '0 0.8rem 0.8rem 0',

      '.badge-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },

  '.hotel-info': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '.location': {
      color: theme.gray300,
      margin: '1.2rem 1rem 1.2rem 0',
      fontSize: '1.6rem',
    },

    '.rating-stars': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.gray300,

      '.review-rating-icon': {
        marginRight: '0.2rem',
      },

      '.review-text': {
        fontSize: '1.6rem',
        color: theme.primary,
        marginLeft: '0.2rem',
        marginRight: '0.6rem',
      },

      '.star-text': {
        fontSize: '1.6rem',
        marginLeft: '0.2rem',
      },
    },
  },

  '.amenities': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: theme.gray800,
    margin: '0.4rem 0',

    '.amenity-icon': {
      marginRight: '0.5rem',
    },

    '.amenities-info': {
      marginRight: '0.8rem',
    },

    '@media (max-width: 768px)': {
      marginTop: '0.8rem',
    },
  },

  '.price-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',

    '.price': {
      display: 'flex',
      flexDirection: 'column',

      '.total-price': {
        color: theme.gray800,
        textTransform: 'capitalize',
        margin: '0.8rem 0',
        fontSize: '1.3rem',

        '@media (max-width: 1px)': {
          margin: '0.4rem 0',
        },
      },

      '.original-price-row': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',

        '.original-price': {
          color: theme.gray300,
          marginRight: '0.8rem',
          textDecoration: 'line-through',
        },

        '.discounted-price': {
          color: hasDiscount ? theme.successDark : theme.gray800,
          fontSize: '1.6rem',
          fontWeight: 600,
        },
      },

      '@media (max-width: 992px)': {
        alignItems: 'flex-start',
      },
    },

    '@media (max-width: 992px)': {
      alignItems: 'flex-start',
      marginTop: '0.4rem',
    },
  },

  '.availability-button': {
    padding: '0.8rem 3.2rem ',
    borderRadius: 0,
    alignSelf: 'flex-end',

    '@media (max-width: 992px)': {
      marginTop: '1rem',
      padding: '0 1.8rem ',
      maxWidth: '100%',
      textWrap: 'wrap',
      alignSelf: 'flex-start',
    },
  },

  '@media (max-width: 992px)': {
    height: 'auto',
  },
});
