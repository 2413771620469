/* eslint-disable jsx-a11y/iframe-has-title */
import {
  useEffect,
  useState,
  // REMOVE TAG HIDE CANCELLATION
  // useRef
} from 'react';
import { useParams } from 'react-router-dom';
// REMOVE TAG HIDE FOR FIRST VERSION
// import moment from 'moment';
import {
  isNil,
  isEmpty,
  camelCase,
  // REMOVE TAG HIDE FOR FIRST VERSION
  //  pick
} from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  Carousel,
  Col,
  ColorBatch,
  Icon,
  Row,
  showApiError,
  Skeleton,
  BookingRoomCart,
  // REMOVE TAG HIDE CANCELLATION
  // CancelBookingModal,
  CheckInOutPetsCart,
  HotelGallery,
  PaymentCart,
  PolicyCart,
  TransactionPaymentDetails,
  useUrlParams,
} from 'components';
// REMOVE TAG HIDE CANCELLATION
// import { BOOKING_STATUSES } from 'enums';
import { validDate } from 'utils';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useUserStore } from 'stores';
import { getBookingById } from 'services';
import { bookingStatusMap } from './config';
import { bookingDetailsContainer, maps } from './styles';

const MyBookingsDetails = ({ history }) => {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const { userInfo } = useUserStore((userState) => pick(userState, ['userInfo']));
  const { getText } = useTranslations();
  const params = useParams();
  // REMOVE TAG HIDE CANCELLATION
  // const modalRef = useRef();
  const { setQueryParams } = useUrlParams();
  const [booking, setBooking] = useState({});
  const bookingStatus = history?.location?.state?.bookingStatus;
  const bookingId = params?.bookingId ?? history.location?.state?.bookingInfo?.bookingId;
  // REMOVE TAG HIDE CANCELLATION
  // const confirmationNumber =
  //   history.location?.state?.bookingInfo?.supplierConfirmationNum ?? booking?.supplierConfirmationNum;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const subscriptionLeft = moment(userInfo?.subscription?.startDate).add(userInfo?.subscription?.duration, 'months');
  // const isLeftSubscription = moment(subscriptionLeft).isBefore(moment());

  useEffect(() => {
    fetchHotelDetails();
    handleScrollToTop();
  }, []);

  const fetchHotelDetails = async () => {
    const [res, err] = await getBookingById(bookingId);
    err ? showApiError(err) : setBooking(res);
  };

  const handleChangePage = () => {
    history.push({
      pathname: `/hotels/${booking?.hotelId}/overview`,
      state: { ...booking, searchId: booking?.searchId, fromBooking: true, provider: booking?.providerName },
    });
    setQueryParams({ searchId: booking?.searchId });
  };

  const handleScrollToTop = () =>
    document.getElementById('#header').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={bookingDetailsContainer} id="#header">
      <div className="gallery-background-container">
        <div className="background" />
        <div className="galleries-container">
          {isNil(booking?.hotelImages) ? (
            <Skeleton width={'100%'} height={500} className="carousel-styles" />
          ) : booking?.hotelImages?.length > 4 ? (
            <HotelGallery hotelImages={booking?.hotelImages} className="hotel-gallery" />
          ) : (
            <Carousel images={booking?.hotelImages} className="carousel-styles" />
          )}
        </div>
      </div>

      <div className="container">
        <div className="booking-badge-id-container">
          {isNil(booking?.bookingId) ? (
            <Skeleton width={100} height={23} className="booking-id" />
          ) : (
            <p className="booking-id">{`${getText('booking')} ${booking?.bookingId}`}</p>
          )}
          <ColorBatch type={bookingStatusMap[bookingStatus ?? booking?.status]?.color} className="booking-badge">
            {getText(`${camelCase(booking?.status)}`)}
          </ColorBatch>
        </div>

        <div className="name-location-actions">
          <div>
            {isNil(booking?.hotelName) ? (
              <Skeleton width={600} height={24} className="title" />
            ) : (
              <h4 className="title">{booking?.hotelName}</h4>
            )}

            {isNil(booking?.hotelId) ? (
              <Skeleton width={200} height={17} className="location" />
            ) : (
              <h4 className="location">{`# ${booking?.hotelId}`}</h4>
            )}

            {isNil(booking?.address) ? (
              <Skeleton width={600} height={17} className="location" />
            ) : (
              <div className="location">
                {booking?.address?.address && <span>{`${booking?.address?.address}`}</span>}
                {booking?.address?.cityName && <span>{`, ${booking?.address?.cityName}`}</span>}
                {booking?.address?.stateName && <span>{`, ${booking?.address?.stateName}`}</span>}
                {booking?.address?.countryName && <span>{`, ${booking?.address?.countryName} `}</span>}
              </div>
            )}
          </div>

          <div className="action-container">
            <Button type="link" className="booking-details-button" onClick={handleChangePage}>
              {getText('propertyDetails')}
              <Icon material iconName="chevron_right" className="icon-expand" />
            </Button>
            {/* REMOVE TAG HIDE CANCELLATION
            {bookingStatus === BOOKING_STATUSES.PAYMENT_COMPLETED &&
              // REMOVE TAG HIDE FOR FIRST VERSION
              // !isLeftSubscription &&
              (isNil(booking?.status) ? (
                <Skeleton width={152} height={48} />
              ) : (
                <Button type="danger" className="danger-button" onClick={() => modalRef.current?.open()}>
                  {getText('cancelBooking')}
                </Button>
              ))} */}
          </div>
        </div>

        <hr className="horizontal-line" />

        <Row className="booking-details" horizontalGap={32}>
          <Col xs={12} sm={12} md={12} lg={7}>
            <h4 className="section-title">{getText('paymentDetails')}</h4>
            {isNil(booking?.priceDetails) ? (
              <Skeleton width="100%" height={170} />
            ) : (
              <PaymentCart paymentInfo={booking?.priceDetails} invoiceInfo={booking} bookingStatus={booking.status} />
            )}

            {!isNil(booking?.roomDetails) && <h4 className="section-title">{getText('roomDetails')}</h4>}
            {isEmpty(booking) ? (
              <Skeleton width="100%" height={507} />
            ) : (
              !isNil(booking?.roomDetails) && <BookingRoomCart bookingInfo={booking?.roomDetails} booking={booking} />
            )}
            {!isNil(booking?.hotelPolices) && (
              <>
                <h4 className="section-title">{getText('hotelPolicies')}</h4>
                <PolicyCart policies={booking?.hotelPolices} />
              </>
            )}

            {!isNil(booking?.hotelPolicies) && (
              <>
                <h4 className="section-title">{getText('hotelPolicies')}</h4>
                <PolicyCart policies={booking?.hotelPolicies} />
              </>
            )}

            {(validDate(booking?.checkInTime ?? booking?.checkIn) &&
              validDate(booking?.checkOutTime ?? booking?.checkOut)) ||
            !isEmpty(booking?.petFriendlyData) ? (
              <CheckInOutPetsCart hotelDetails={booking} />
            ) : (
              <Skeleton width={363} height={136} />
            )}

            {!isNil(booking?.location?.latitude) && !isNil(booking?.location?.longitude) && (
              <h4 className="section-title">{getText('whereYouWillBe')}</h4>
            )}
            {isEmpty(booking) ? (
              <Skeleton width="100%" height={350} />
            ) : (
              !isNil(booking?.location?.latitude) &&
              !isNil(booking?.location?.longitude) && (
                <iframe
                  src={`https://maps.google.com/maps?q=${booking?.location?.latitude},${booking?.location?.longitude}&hl=es;&output=embed`}
                  width="100%"
                  height="380"
                  style={maps}
                  aria-hidden="false"
                />
              )
            )}
          </Col>

          <Col xs={12} sm={12} md={12} lg={5}>
            <h4 className="section-title">{getText('transactionDetails')}</h4>

            {booking?.transactions?.map((el, index) => (
              <TransactionPaymentDetails key={index} transaction={el} fromBooking className="transaction-details" />
            ))}
          </Col>
        </Row>
      </div>

      {/* REMOVE TAG HIDE CANCELLATION*/}
      {/* <CancelBookingModal ref={modalRef} bookingId={booking?.bookingId} confirmationNumber={confirmationNumber} /> */}
    </div>
  );
};

export default MyBookingsDetails;
