import { useEffect, useRef, useState } from 'react';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
// REMOVE TAG HIDE FOR FIRST VERSION
// import {  useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  BenefitsModal,
  Button,
  Col,
  HomeFilterBar,
  Image,
  Row,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // PriceLabel,
  // showApiError,
  // LeftSubscriptionBanner,
  // SubscriptionCart,
} from 'components';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { SUBSCRIPTION_TYPES, DEFAULT_CURRENCY } from 'enums';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useUserStore, initSubscriptionTypes, useSubscriptionTypes } from 'stores';
// import { getAllSubscriptions } from 'services';
import { MEMBER_BENEFITS, MORE_FROM_ONE_VOYAGE, REVIEW_RATE_DATA } from './config';
import { hotelContainer } from './styles';

const HotelsHome = () => {
  const { getText } = useTranslations();
  // // REMOVE TAG HIDE FOR FIRST VERSION
  // const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  // const subscriptionTypes = useSubscriptionTypes();
  // const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const [selectedBenefits, setSelectedBenefits] = useState({});
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const [subscriptions, setSubscriptions] = useState([]);
  const modalRef = useRef();
  const carouselRef = useRef();

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const convertedToLocalCurrencyPrice = (price) =>
  //   price &&
  //   <PriceLabel
  //   fiat={Number(price)}
  //   fiatOriginCurrency={DEFAULT_CURRENCY.code}
  //   fiatDesiredCurrency={selectedCurrency?.code}
  //   fiatOnly={false}
  // />

  // const subscriptionWithIncludes = subscriptions
  //   ?.map((el) => ({
  //     ...el,
  //     includes: subscriptionTypes.find((item) => item.name === el.subscriptionTypeName)?.includes,
  //   }))
  //   .filter((item) => item.isActive && item.isVisible);

  // const filteredSubscriptions = (type) =>
  //   subscriptionWithIncludes
  //     ?.filter((el) => el?.subscriptionTypeName?.toLowerCase() === type?.toLowerCase())
  //     ?.map((el) => ({
  //       ...el,
  //       label: <p>
  // {convertedToLocalCurrencyPrice(el?.price)} <span>{` - ${el?.duration} ${getText('monthsDuration')}`}</span>
  // </p>,
  //       value: el.id,
  //     }));

  useEffect(() => {
    // REMOVE TAG HIDE FOR FIRST VERSION
    // initSubscriptionTypes();
    // fetchAllSubscriptions();
    handleScrollToTop();
  }, []);

  useEffect(() => {
    selectedBenefits && carouselRef?.current?.goToSlide(selectedBenefits?.id, true);
  }, [selectedBenefits?.id]);

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const fetchAllSubscriptions = async () => {
  //   const [res, err] = await getAllSubscriptions({ pageIndex: 1, pageSize: 500, isVisible: true, isAvailable: true });
  //   err ? showApiError(err) : setSubscriptions(res?.results);
  // };

  const handleOpenBenefitsModal = (value) => {
    modalRef.current?.open();
    setSelectedBenefits(value);
  };

  const handleScrollToTop = () =>
    document.getElementById('#home-header').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={hotelContainer} id="#home-header" data-id="hotel-home-page">
      {/*
      REMOVE TAG HIDE FOR FIRST VERSION
       {isAuthenticated && <LeftSubscriptionBanner show className="subscription-banner" />} */}
      <div className="background-photo" data-id="hotel-home-page-background-photo">
        <div className="text" data-id="hotel-home-page-background-photo-info-container">
          <h1 className="save-text" data-id="hotel-home-page-background-photo-info-title">
            {getText('saveBigNextTrip')}
          </h1>
          <p className="subtitle" data-id="hotel-home-page-background-photo-info-subtitle">
            {getText('withGreatDeals')}
          </p>
        </div>
        <Image
          src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/home-header.png"
          className="header-logo"
          dataId="hotel-home-page-background-photo-info-header-image"
        />
        <HomeFilterBar dataId="hotel-home-page-filter-bar" />
      </div>
      <div className="title" data-id="hotel-home-page-benefits-title">
        {getText('as')}{' '}
        <span className="primary-text" data-id="hotel-home-page-benefits-title-member-text">
          {getText('member')}
        </span>{' '}
        {getText('youReceiveMoreBenefits')}
      </div>
      <Row className="benefits-container" dataId="hotel-home-page-benefits-row">
        {MEMBER_BENEFITS(getText).map((el, ind) => (
          <Col
            dataId={`hotel-home-page-${el.title}-benefit-column`}
            horizontalGap={24}
            lg={4}
            md={6}
            sm={12}
            key={ind}
            onClick={() => handleOpenBenefitsModal(el)}>
            <Image src={el.icon} className="image" dataId={`hotel-home-page-${el.title}-benefit-icon`} />
            <h5 className="benefits-title" data-id={`hotel-home-page-${el.title}-benefit-title`}>
              {el.title}
            </h5>
            <p className="benefits-subtitle" data-id={`hotel-home-page-${el.title}-benefit-subtitle`}>
              {el.subtitle}
            </p>
          </Col>
        ))}
      </Row>
      {/*
      REMOVE TAG HIDE FOR FIRST VERSION
      <div className="subscription-container">
        <h2 className="subscription-title">{getText('chooseTheRightPlan')}</h2>
        <p className="subscription-subtitle">{getText('chooseSubscriptionDescription')}</p>
        <Row gap={16} dFlex>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.BASIC}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.BASIC)}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.PREMIUM}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.PREMIUM)}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.GOLDEN_UNLIMITED}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.GOLDEN_UNLIMITED)}
            />
          </Col>
        </Row>
      </div> */}
      <Row gap={8} className="more-container" dataId="hotel-home-page-more-row">
        <Col lg={4} md={6} sm={12} dataId="hotel-home-page-more-column">
          <h5 className="more-container-title" data-id="hotel-home-page-more-title">
            {getText('moreFormOneVoyage')}
          </h5>
          <p className="more-subtitle" data-id="hotel-home-page-more-subtitle">
            {getText('moreFormOneVoyageDescription')}
          </p>
        </Col>
        {MORE_FROM_ONE_VOYAGE(getText).map((el, ind) => (
          <Col
            lg={4}
            md={6}
            sm={12}
            key={ind}
            className="more-column"
            dataId={`hotel-home-page-more-${el.title}-column`}>
            <Image src={el.image} className="more-image" dataId={`hotel-home-page-more-${el.title}-image`} />
            <div className="more-info-container" data-id={`hotel-home-page-more-${el.title}-container`}>
              <h5 className="more-title" data-id={`hotel-home-page-more-${el.title}-title`}>
                {el.title}
              </h5>
              <p className="more-subtitle" data-id={`hotel-home-page-more-${el.title}-subtitle`}>
                {el.subtitle}
              </p>
            </div>
            {el.isComingSoon && (
              <p className="coming-soon-container" data-id={`hotel-home-page-more-${el.title}-coming-soon-sticker`}>
                {getText('comingSoon')}
              </p>
            )}
          </Col>
        ))}
      </Row>
      <hr className="horizontal-line" data-id="hotel-home-page-horizontal-line" />
      <div className="rated-container" data-id="hotel-home-page-rated-container">
        <h3 className="rated-title" data-id="hotel-home-page-rated-title">
          {getText('ratedExcellentStaysByOurCustomers')}
        </h3>
        <p className="rated-description" data-id="hotel-home-page-rated-description">
          {getText('ratedExcellentStaysByOurCustomersDescription')}
        </p>
        <Row className="rated-row" dataId="hotel-home-page-rated-row">
          {REVIEW_RATE_DATA.map((el, ind) => (
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={ind}
              className="rated-column"
              dataId={`hotel-home-page-rated-user-${el.id}-column`}>
              <Image
                src={el.userImage}
                className="rated-user-image"
                dataId={`hotel-home-page-rated-user-${el.id}-image`}
              />
              <p
                className="rated-reservation-address"
                data-id={`hotel-home-page-rated-user-${el.id}-reservation-address`}>
                {el.reservationAddress}
              </p>
              <div className="rated-stars" data-id={`hotel-home-page-rated-user-${el.id}-stars`}>
                {Array(el.reviewRating)
                  .fill()
                  .map((_, index) => (
                    <Image
                      key={index}
                      src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/star-yellow-icon.png"
                      className="yellow-star-image"
                      data-id={`hotel-home-page-rated-user-${el.id}-stars-icon`}
                    />
                  ))}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="help-container" data-id="hotel-home-page-help-container">
        <Image
          src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/help-image.png"
          className="help-image"
          dataId="hotel-home-page-help-image"
        />
        <div className="help-info-container" data-id="hotel-home-page-help-info-container">
          <h5 className="help-title" data-id="hotel-home-page-help-info-title">
            {getText('needHelp')}?
          </h5>
          <p className="help-subtitle" data-id="hotel-home-page-help-info-subtitle">
            {getText('needHelpDescription')}
          </p>
          <Button small className="help-button" dataId="hotel-home-page-help-contact-button">
            {getText('contactSales')}
          </Button>
        </div>
      </div>
      <BenefitsModal
        ref={modalRef}
        dataId={`hotel-home-page-benefits-modal-${selectedBenefits?.title}`}
        allBenefits={MEMBER_BENEFITS}
        selectedBenefits={selectedBenefits}
      />
    </div>
  );
};

export default HotelsHome;
