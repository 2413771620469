import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { counterInputContainer, icon } from './styles';

const CounterInput = forwardRef((props, ref) => {
  const { value, onChange, isOutOfRange, className, dataId } = props;
  const [newValue, setNewValue] = useState(value ?? 0);
  const isDisabled = newValue === 0 ?? value === 0;

  useEffect(() => {
    onChange(newValue);
  }, [newValue]);

  useImperativeHandle(ref, () => ({
    value: newValue,
  }));

  const handleRemoveNumber = (e) => {
    if (isDisabled) return e.preventDefault();

    setNewValue((prev) => prev - 1);
  };

  const handleAddNumber = () => {
    !isOutOfRange && setNewValue((prev) => prev + 1);
  };

  return (
    <div
      css={counterInputContainer(props)}
      {...(className && { className })}
      data-id={`${dataId}-counter-input-container`}>
      <Icon
        material
        iconName="do_not_disturb_on"
        size={44}
        css={icon(isDisabled)}
        dataId={`${dataId}-counter-input-icon-subtract`}
        onClick={handleRemoveNumber}
      />
      <p className="number" data-id={`${dataId}-counter-input-value`}>
        {newValue}
      </p>
      <Icon
        material
        iconName="add_circle"
        size={44}
        css={icon()}
        dataId={`${dataId}-counter-input-icon-add`}
        onClick={handleAddNumber}
      />
    </div>
  );
});

CounterInput.propTypes = {
  value: PropTypes.number,
  isOutOfRange: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default CounterInput;
