import PropTypes from 'prop-types';
import { skeletonClass } from './styles';

const Skeleton = (props) => {
  const { count, className, dataId } = props;

  const elements = [];

  for (let i = 0; i < count; i++) {
    elements.push(
      <span key={i} css={skeletonClass(props)} {...(className && { className })} data-id={`${dataId}-skeleton`}>
        &zwnj;
      </span>,
    );
  }

  return elements;
};

Skeleton.defaultProps = {
  count: 1,
  duration: '1.2',
};

Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default Skeleton;
