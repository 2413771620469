import { Font } from '@react-pdf/renderer';
import { theme } from 'styles';
import Roboto from 'assets/fonts/roboto/Roboto.ttf';
import RobotoMedium from 'assets/fonts/roboto/RobotoMedium.ttf';
import RobotoBold from 'assets/fonts/roboto/RobotoBold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [{ src: Roboto }, { src: RobotoMedium, fontWeight: 600 }, { src: RobotoBold, fontWeight: 800 }],
});

export const page = {
  padding: '32px 48px',
};

export const invoiceButton = {
  color: theme.black,
  fontWeight: 600,
  borderRadius: 10,

  '.icon-download': {
    color: theme.black,
    marginRight: 8,
  },
};

export const wrap = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '24px 0',
};

export const date = {
  color: theme.gray300,
  fontSize: 14,
  marginBottom: 32,
};

export const wrappedContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 32,
};

export const bookingNumberContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
};

export const bookingLabel = {
  fontSize: 14,
  color: theme.gray400,
};

export const bookingValue = {
  fontSize: 12,
  fontWeight: 600,
  color: theme.gray800,
};

export const invoiceSectionTitle = {
  fontSize: 15,
  fontWeight: 600,
  paddingBottom: 8,
  borderBottom: `1px solid ${theme.gray150}`,
  margin: '24px 0',
};

export const sides = {
  flex: 1,
};
