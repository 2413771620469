import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Localization, User } from 'components';
import { ROUTES } from 'enums';
import { useUserStore } from 'stores';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { navBarHeaderContainer } from './styles';

const NavBar = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();

  return (
    <header css={navBarHeaderContainer} data-id="navigation-header-container">
      <Logo
        className="logo"
        data-id="navigation-project-logo"
        onClick={() => {
          history.push(ROUTES.Hotels);
        }}
      />

      <div className="buttons-container" data-id="navigation-buttons-container">
        <Button
          small
          type="link"
          className="nav-button"
          dataId="navigation-buttons-hotels"
          linkTo={ROUTES.Hotels}
          onClick={() => history.push(ROUTES.Hotels)}>
          {getText('hotels')}
        </Button>
        <Button small type="link" linkTo={ROUTES.Benefits} dataId="navigation-buttons-benefits" className="nav-button">
          {getText('benefits')}
        </Button>
        <Button small type="link" linkTo={ROUTES.Contact} dataId="navigation-buttons-contacts" className="nav-button">
          {getText('contact')}
        </Button>
        <Button
          type="default"
          small
          leftIcon={<Icon iconName="download" className="download-icon" />}
          dataId="navigation-buttons-get-app"
          className="nav-mobile-button"
          onClick={() => {}}>
          {getText('getApp')}
        </Button>
      </div>

      <div className="right-container" data-id="location-user-log-in-container">
        <Localization className="languages-container" />

        {isAuthenticated ? (
          <User />
        ) : (
          <Button small className="login-styles" dataId="navigation-button-sign-in" linkTo={ROUTES.Login}>
            {getText('signIn')}
          </Button>
        )}
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
