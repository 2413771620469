import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import {
  checkButtonContainer,
  checkButtonIcon,
  checkButtonContainerStyles,
  errorStyles,
  helpContent,
  helpIcon,
} from './styles';
import { getText } from '@veraio/strank';

const CheckButton = (props) => {
  const { children, onError, value, disabled, onChange, className, required, isTouched, radio, dataId } = props;

  const [isChecked, setIsChecked] = useState(!!value);
  const [error, setError] = useState(false);

  const iconName = radio ? 'adjust' : 'check';

  useEffect(() => {
    setIsChecked(!!value);
  }, [!!value]);

  useEffect(() => {
    isTouched && checkForError(isChecked);
  }, [isTouched]);

  const handleChange = () => {
    if (disabled) return;
    setIsChecked((prev) => {
      isFunction(onChange) && onChange(!prev);
      checkForError(!prev);
      return !prev;
    });
  };

  const checkForError = (err) => {
    if (required && !err && isFunction(onError)) {
      onError({ msg: 'Required' });
      setError(true);
    } else setError(false);
  };

  return (
    <div data-id={`${dataId}-check-button-container`}>
      <div
        css={checkButtonContainerStyles}
        {...(className && { className })}
        data-id={`${dataId}-check-button-container-styles`}>
        <div
          role="checkbox"
          tabIndex={0}
          css={checkButtonContainer(!!children, isChecked, props)}
          aria-checked={isChecked}
          data-id={`${dataId}-check-button-container-container`}
          onClick={handleChange}>
          <Icon
            material
            iconName={iconName}
            color="white"
            size={18}
            css={checkButtonIcon(isChecked)}
            dataId={`${dataId}-check-button-container-check-icon`}
          />
        </div>
        <span className="label" data-id={`${dataId}-check-button-container-check-label`}>
          {children}
        </span>
      </div>
      {error && (
        <div css={helpContent} data-id={`${dataId}-check-button-error-container`}>
          <Icon material iconName="error" css={helpIcon} size={14} dataId={`${dataId}-check-button-error-icon`} />
          <span css={errorStyles} data-id={`${dataId}-check-button-error-label`}>
            {getText('required')}!
          </span>
        </div>
      )}
    </div>
  );
};

CheckButton.displayName = 'CheckBox';

CheckButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  children: PropTypes.any,
  radio: PropTypes.bool,
  dataId: PropTypes.string,
};

export default CheckButton;
