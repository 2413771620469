import PropTypes from 'prop-types';
import { printPrice } from '@oneecosystem/dealshaker-core';
import { priceLabelStyles } from './styles';

const PriceLabel = (props) => {
  // Always turn off default stringify, because the component always return react object
  const { fiatLabel, cryptoLabel } = printPrice({ ...props, stringify: false });

  if (!cryptoLabel) {
    return (
      <span dir="auto" css={priceLabelStyles} data-id={`${props?.dataId}-fiat-price-label`}>
        {fiatLabel}
      </span>
    );
  }
  if (!fiatLabel) {
    return (
      <span css={priceLabelStyles} data-id={`${props?.dataId}-crypto-price-label`}>
        {cryptoLabel}
      </span>
    );
  }

  return (
    <>
      <span dir="auto" css={priceLabelStyles} data-id={`${props?.dataId}-fiat-price-label`}>
        {fiatLabel}
      </span>
      &nbsp;+&nbsp;
      <span css={priceLabelStyles} data-id={`${props?.dataId}-crypto-price-label`}>
        {cryptoLabel}
      </span>
    </>
  );
};

PriceLabel.propTypes = {
  stringify: PropTypes.bool,
  dataId: PropTypes.string,
};

export default PriceLabel;
