import { useState } from 'react';
import { capitalize, isArray, isNil, isPlainObject } from 'lodash-es';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { Button, showError, Form, Input, inputValidation, Image, Icon } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { CAROUSED_IMAGES, CAROUSEL_RESPONSIVE_DATA } from './config';
import { publicContentContainer } from './styles';

const Login = ({ history, location }) => {
  const { getText } = useTranslations();
  const { state } = location;
  const [show, setShow] = useState(false);

  const handleLogin = async (data) => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));
    //     Error from sts identity                  Default behavior
    if (err) return isArray(err) ? err.forEach(showError) : showError(err);

    if (state?.navigateTo) history.replace({ pathname: state.navigateTo, state: state?.item });

    if (!err && isNil(state?.navigateTo)) history.replace('/');

    return [res, err];
  };

  return (
    <div css={publicContentContainer} data-id="login-page-container">
      <Logo className="logo" data-id="login-page-logo" onClick={() => history.push('/')} />
      <Carousel
        infinite
        pauseOnHover
        showDots
        autoPlay
        rewindWithAnimation
        arrows={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        autoPlaySpeed={3000}
        transitionDuration={3000}
        customTransition="all .5"
        containerClass="carousel-slider"
        dotListClass="dot-list-style"
        responsive={CAROUSEL_RESPONSIVE_DATA}>
        {CAROUSED_IMAGES?.map((el, ind) => (
          <Image key={ind} src={el} className="carousel-image" dataId={`login-page-carousel-image-${ind}`} />
        ))}
      </Carousel>

      <Form className="form-container" dataId="login-page-form-container" onSubmit={handleLogin}>
        <h3 className="title" data-id="login-page-form-title">
          {getText('logIn')}
        </h3>
        <Input
          required
          formId="email"
          margin="0 0 1em"
          placeholder="name@example.com"
          label={getText('emailAddress')}
          dataId="login-page-form-input-email"
          validate={inputValidation('required', 'email')}
        />
        <Input
          required
          type={show ? 'text' : 'password'}
          formId="password"
          margin="0 0 1em"
          className="password-input"
          placeholder={getText('password')}
          label={getText('password')}
          dataId="login-page-form-input-password"
          rightIcon={
            <Icon
              iconName={show ? 'visibility_off' : 'visibility'}
              color="primary"
              className="eye-icon"
              dataId="login-page-form-input-icon-visibility"
              onClick={() => setShow((prev) => !prev)}
            />
          }
          validate={inputValidation('required', 'password')}
        />
        <div className="forgot-container" data-id="login-page-forgot-password-container">
          <Button
            type="link"
            linkTo={ROUTES.ForgotPassword}
            className="forgot-button"
            dataId="login-page-forgot-password-button">
            {getText('forgotPassword')}?
          </Button>
        </div>

        <div className="action-container" data-id="login-page-action-container">
          {({ onSubmit }) => (
            <Button small className="login-button" dataId="login-page-log-in-button" onClick={onSubmit}>
              {getText('logIn')}
            </Button>
          )}
        </div>

        <div data-id="login-page-agree-terms-privacy-container">
          <div className="paragraph" data-id="login-page-agree-terms-privacy">
            {`${getText('byContinuingYouAgree')} `}
            <Button
              type="link"
              dataId="login-page-agree-terms-button"
              className="link-button"
              onClick={() => history.push(ROUTES.TermsAndConditions)}>
              <span data-id="login-page-agree-terms-button-label">{getText('termsOfService')}</span>
            </Button>{' '}
            <span data-id="login-page-agree-and">{getText('and')}</span>{' '}
            <Button
              type="link"
              dataId="login-page-agree-privacy-button"
              className="link-button"
              onClick={() => history.push(ROUTES.PrivacyPolicy)}>
              <span data-id="login-page-agree-privacy-button-label">{capitalize(getText('privacyPolicy'))}.</span>
            </Button>
          </div>
          <div className="last-container" data-id="login-page-logo-no-accounts-action-container">
            <div className="logo-account-container" data-id="login-page-logo-no-accounts-container">
              <Image
                src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/logo-icon.png"
                className="logo-project"
                dataId="login-page-logo-no-accounts-image-logo"
              />
              <div data-id="login-page-no-accounts-container">
                <p className="no-account-text" data-id="login-page-no-accounts-text">
                  {getText('noAccountYet')}
                </p>
                <p className="more-about" data-id="login-page-more-about-text">
                  {getText('moreAboutOneEcosystem')}
                </p>
              </div>
            </div>
            <Button
              type="secondary"
              className="create-button"
              dataId="login-page-create-account-button"
              onClick={() => history.push(ROUTES.PrivacyPolicy)}>
              {getText('createAccount')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
