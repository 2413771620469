/* eslint-disable react/prop-types */
import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import { Button } from '../Button';
import { useForm } from './useForm';
import { formContainer } from './styles';

const Form = forwardRef((props, ref) => {
  const { className, submitButton, cancelButton, dataId } = props;

  const { loading, handleSubmit, newChildren, formOptions, reset } = useForm(props);

  useImperativeHandle(ref, () => ({
    ...formOptions,
    reset,
    submit: handleSubmit,
  }));

  const SubmitButton = ({ children, ...rest }) => (
    <Button disabled={loading} {...rest} dataId={`form-container-${dataId}-submit-button`} onClick={handleSubmit}>
      {children}
    </Button>
  );

  const CancelButton = ({ children, ...rest }) => (
    <Button disabled={loading} {...rest} dataId={`form-container-${dataId}-cancel-button`} onClick={reset}>
      {children || 'Cancel'}
    </Button>
  );

  const onKey = (el) => {
    el.keyCode === 13 && handleSubmit();
  };

  return (
    <div css={formContainer(props)} {...(className && { className })} data-id={`form-container-${dataId}`}>
      <div onKeyDown={onKey} role="presentation" data-id={`form-container-presentation-${dataId}`}>
        {newChildren}
      </div>

      {isPlainObject(cancelButton) && (
        <CancelButton {...cancelButton}>{cancelButton.children || 'Cancel'}</CancelButton>
      )}

      {submitButton && <SubmitButton {...submitButton}>{submitButton?.children || 'Submit'}</SubmitButton>}
    </div>
  );
});

Form.propTypes = {
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  submitButton: PropTypes.object,
  renderSubmit: PropTypes.func,
  dataId: PropTypes.string,
};

export default Form;
