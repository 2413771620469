import { commonStyles } from '.';

export default {
  primary: '#00a355',
  primaryLight: '#bfffee',
  primaryDark: '#007253',
  secondary: '#e1f8e5',
  secondaryLight: '#f9fffb',
  secondaryLightLight: '#F4F9FA',
  secondaryLightDark: '#f3fff7',
  secondaryDark: '#9eaea3',
  secondaryLightDarker: 'rgba(243, 225, 247, 0.06)',
  blue: '#0070D1',
  blueDark: '#212E4B',
  blueDarker: '#395290',
  blueDarker2: '#4762A3',
  blueDarkLight: '#163A63',
  blueLight: '#98CBF6',
  blueLighter: '#E4F3FF',
  gray: '#5f636f',
  whiteGray: '#e9eaf0',
  gray50: '#F5F6F7',
  gray100: '#EEEFF1',
  gray150: '#D4D7DE',
  gray200: '#e7e6eb',
  gray300: '#636363',
  gray400: '#b8b7bc',
  gray500: '#95919f',
  gray600: '#676370',
  gray700: '#3a3841',
  gray800: '#3a3841',
  gray900: '#414044',
  gray950: '#100F12',
  black: '#000000',
  blackLight: '#0C1E1B',
  white: '#ffffff',
  whiteDark: '#EFEFEF',
  info: '#40AAFB',
  infoLight: '#DAEBFF',
  infoLighter: '#BEE1FF',
  infoLightDarker: '#C7D7FF',
  infoDark: '#0F6EDE',
  warning: '#ffab02',
  warningLight: '#F2D746',
  warningLighter: '#FFF8DD',
  warningDark: '#9e4a03',
  success: '#00c177',
  successDark: '#1A8237',
  successLight: '#90EFB5',
  successLighter: '#C8F1D3',
  error: '#F63C25',
  errorLight: '#f2d8d5',
  errorDark: '#DA1A0E',
  highlight: '#458FFF',
  pink: '#EAC1F9',
  boxShadowLight: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
  boxShadow: '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)',
  boxShadowDark: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
  boxShadowDarker: '0px 4px 14px 0px rgba(0, 0, 0, 0.13)',
  boxShadowLighter: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  rootFontSize: 10,
  sm: 576,
  md: 786,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  borderRadiusS: 8,
  borderRadiusM: 16,
  borderRadiusL: 24,
  spaceXXS: 4,
  spaceXS: 8,
  spaceS: 12,
  spaceM: 16,
  spaceL: 20,
  spaceXL: 24,
  spaceXXL: 32,
  spaceXXXL: 48,
  spaceXXXXL: 64,
  lineAwesome: true,
  margin: (value) => ({ margin: value }),
  padding: (value) => ({ padding: value }),
  flex: (value) => ({ flex: value }),
  displayFlex: ({ align, justify, direction, wrap, flow } = {}) => ({
    display: 'flex',
    ...(align && { alignItems: align }),
    ...(justify && { justifyContent: justify }),
    ...(direction && { flexDirection: direction }),
    ...(wrap && { flexWrap: wrap }),
    ...(flow && { flexFlow: flow }),
  }),
  styles: (properties) => commonStyles(properties),
};
