import { camelCase, isNil, pick } from 'lodash-es';
import { ColorBatch } from 'components';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_TYPE } from 'enums';
import { replacePathParams } from 'utils';
import { providerErrorContainer, transactionIdContainer, buttonCursor } from './styles';

export const transactionInfo = (getText, history, fromBooking) => [
  {
    label: 'transactionId',
    value: 'id',
    render: (row) => (
      <div
        role="button"
        tabIndex={0}
        onClick={() =>
          fromBooking &&
          history.push({
            pathname: `/transactions/details/${row?.id}`,
            state: { row },
          })
        }
        css={transactionIdContainer(fromBooking)}>
        {row.id ? `# ${row.id}` : 'N/A'}
      </div>
    ),
  },
  {
    label: 'dateOfCreation',
    value: 'createdDate',
    dateTime: true,
  },
  {
    label: 'status',
    render: (row) => (
      <ColorBatch withoutStartCase type={TRANSACTION_STATUS_COLOR[row.status]?.color}>
        {getText(TRANSACTION_STATUS_TYPE[row?.status])}
      </ColorBatch>
    ),
  },
  {
    label: 'kindOfTransaction',
    render: (row) => getText(getText(camelCase(row.transactionKind))),
  },
  {
    label: 'subKindOfTransaction',
    render: (row) => getText(getText(camelCase(row.transactionSubKind))),
  },
  {
    label: 'reason',
    value: 'reason',
  },
  {
    label: 'providerError',
    value: 'providerError',
    render: (row) =>
      row?.providerError ? (
        <div css={providerErrorContainer}>
          <div className="row">
            <div className="provider-error-dot" />
            <p> {getText('yes')}</p>
          </div>
          <p>{row?.providerError}</p>
        </div>
      ) : (
        <div css={providerErrorContainer}>
          <div className="row">
            <div className="provider-success-dot" />
            <p>{getText('no')}</p>
          </div>
        </div>
      ),
  },
  {
    label: 'bookingId',
    value: 'bookingId',
    render: (row) => (
      <div
        role="button"
        tabIndex={0}
        css={fromBooking && buttonCursor}
        onClick={() =>
          !isNil(row.bookingId) &&
          !fromBooking &&
          history.push({
            pathname: replacePathParams('/bookings/:bookingStatus/:bookingId', {
              bookingStatus: row?.bookingStatus,
              bookingId: row?.bookingId,
            }),
            state: {
              bookingInfo: { ...pick(row, 'bookingId') },
              bookingStatus: row?.bookingStatus,
            },
          })
        }>
        {row.bookingId ? `# ${row.bookingId}` : 'N/A'}
      </div>
    ),
  },
];
