import { theme } from 'styles';

export const container = {
  position: 'relative',

  '.to-top-button': {
    position: 'absolute',
    bottom: 32,
    right: 20,
    textAlign: 'center',

    '.button': {
      borderRadius: 50,
      width: '5.7rem',
      height: '5.7rem',
      marginBottom: '.4rem',

      '.button-to-top-arrow': {
        '@media (max-width: 576px)': {
          fontSize: '1.4rem',
        },
      },

      '@media (max-width: 992px)': {
        width: '3.7rem',
        height: '3.7rem',
      },
    },

    '.text': {
      fontWeight: 500,
      color: theme.gray700,

      '@media (max-width: 576px)': {
        width: '3rem',
      },
    },

    '@media (max-width: 992px)': {
      right: 0,
      bottom: 72,
    },

    '@media (max-width: 576px)': {
      right: 4,
      bottom: 72,
    },
  },
};

export const filtersContainer = {
  padding: '0 11.2rem',

  '@media (max-width: 992px)': {
    padding: '0 6.4rem',
  },

  '@media (max-width: 567px)': {
    padding: '0 3rem',
  },
};

export const scrollableContainer = (hasScroll) => [
  {
    padding: '0 11.2rem',
    display: 'flex',
    flexDirection: 'column',
    // REMOVE TAG HIDE FOR FIRST VERSION
    // height: hasScroll && 'calc(100vh - 280px)',
    height: hasScroll && 'calc(100vh - 20rem)',
    overflow: 'auto',

    '.pagination': {
      paddingBottom: '1.6rem',
    },

    '.amenity-badge': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 1.6rem',

      '& > *': {
        marginRight: '.8rem',
      },
    },

    '.amenities-styles': {
      padding: '1rem',
    },

    '.not-have-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.not-have-title': {
        color: theme.gray800,
        fontWeight: 500,
      },

      '.not-have-subtitle': {
        color: theme.gray700,
      },

      '& > svg': {
        maxHeight: '30rem',
      },
    },

    '@media (max-width: 992px)': {
      padding: '0 6.4rem',
    },

    '@media (max-width: 567px)': {
      padding: '0 3rem',
    },
  },
];

export const banner = {
  backgroundColor: theme.primary,
  width: '100%',
  height: '10rem',
  zIndex: 1,
};

export const badgeContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
