import { theme } from 'styles';

export const mainContainer = {
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
};

export const sideNavContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.white,
  width: '100%',
  height: '6.4rem',
  flexShrink: 0,
  transition: 'width .3s ease-in-out',
  zIndex: 1000,
};

export const sideNavHeader = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '5.6rem',
  padding: '0 16px',
  zIndex: 10000,
  backgroundColor: theme.white,

  '.logo-container': {
    overflowX: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    minWidth: '3rem',
  },

  '.logo': {
    width: 130,
    minWidth: '2.8rem',
  },

  '.action-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.gray300,
    backgroundColor: theme.white,
    cursor: 'pointer',

    '.login-styles': {
      padding: '8px 16px',
    },

    '.icon': {
      color: theme.gray300,
      padding: '0 24px',
    },
  },
};

export const menuListContainer = (isOpen) => [
  {
    overflow: 'hidden auto',
    height: 'auto',
    padding: '1.2em',
    position: 'absolute',
    top: 0,
    right: 10,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    transition: 'width .3s ease-in-out',
    zIndex: 10000,
    width: '30rem',
    backgroundColor: theme.white,
    borderRadius: 10,
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '5rem',
  },
];

export const subMenuList = {
  backgroundColor: theme.gray500,
  color: theme.gray300,
  borderRadius: 4,
  minWidth: 120,
};

export const subMenusTreeContainer = {
  padding: '0 1rem 0 0.5rem',
  fontWeight: 600,
  fontSize: '1.8rem',
  color: theme.black,
  textTransform: 'upperCase',
};

export const contentContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto hidden',
};

export const sideNavContent = (isMobile) => ({
  minHeight: 'calc(100vh - 104px)',
  width: '100%',
  flex: 1,
  overflow: 'auto',
  overflowX: isMobile && 'hidden',
});

export const tooltipContainer = {
  display: 'block',
};

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0 0 0 0.2em',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginLeft: 32,
    borderRadius: 40,
  },
};

export const menuItem = {
  color: theme.blackLight,
  backgroundColor: 'transparent',
  padding: '1em 0',
  margin: '0 0.6rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  marginBottom: '1rem',
  lineHeight: 1,
  whiteSpace: 'nowrap',
  borderBottom: `1px solid ${theme.black10}`,
  fontSize: '1.6rem',
  fontWeight: 400,
  width: '100%',

  '&:hover': {
    color: theme.gray500,
    backgroundColor: theme.white,
    fontWeight: 500,
  },

  '&.active': {
    color: theme.blackLight,
    backgroundColor: theme.white,
    fontWeight: 500,
  },
};

export const menuItemContent = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const menuSubItem = {
  color: theme.black,
  backgroundColor: 'transparent',
  padding: '1em 0.8em',
  cursor: 'pointer',
  borderRadius: 4,
  display: 'block',
  whiteSpace: 'nowrap',
  fontWeight: 400,

  '&:hover': {
    color: theme.black,
  },

  '&.active': {
    color: theme.black,
    fontWeight: 500,
  },
};

export const menuItemText = {
  display: 'block',
};

export const menuItemRightIcon = {
  color: theme.gray300,
};
