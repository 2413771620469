import PropTypes from 'prop-types';
import { startCase } from 'lodash-es';
import { colorStatus } from './styles';

const ColorBatch = (props) => {
  const { children, withoutStartCase, className, onClick, dataId } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      css={colorStatus(props)}
      {...(className && { className })}
      data-id={`${dataId}-color-batch-container`}
      onClick={onClick}>
      {withoutStartCase ? children : startCase(children)}
    </div>
  );
};

ColorBatch.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  withoutStartCase: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default ColorBatch;
