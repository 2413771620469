import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon } from 'components';
import LocalizationModal from './LocalizationModal';
import { localizationContainer } from './styles';

const Localization = ({ isMobile }) => {
  const { getText } = useTranslations();
  const modalRef = useRef();

  return (
    <>
      <div
        role="button"
        data-id="localization-header-container"
        tabIndex={0}
        css={localizationContainer(isMobile)}
        onClick={() => modalRef.current.open()}>
        {isMobile ? (
          <Button
            small
            type="secondary"
            leftIcon={<Icon iconName="language" className="icon" size={28} />}
            className="button-container"
            dataId="localization-header-button-language-and-currency">
            {getText('languageAndCurrency')}
          </Button>
        ) : (
          <div className="content-container" data-id="localization-header-icon-container">
            <Icon iconName="language" size={24} dataId="localization-header-icon-language" />
          </div>
        )}
      </div>

      <LocalizationModal ref={modalRef} dataId="localization-header-modal-container" />
    </>
  );
};

Localization.propTypes = {
  isMobile: PropTypes.string,
};

export default Localization;
