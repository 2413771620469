import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { usePopper } from 'react-popper';
import BodyOverflow from '../BodyOverflow';
import { tooltipContainer, popupTooltip, arrow } from './styles';

const Tooltip = (props) => {
  const {
    children,
    content,
    onClick,
    open,
    className,
    placement = 'right',
    strategy,
    offset,
    noArrow = false,
    closeOnClick = false,
    zIndex,
    contentClassName,
    mainContainerProps,
    dataId,
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      !noArrow && { name: 'arrow', options: { element: arrowElement } },
      offset && { name: 'offset', options: { offset } },
    ].filter(Boolean),
    placement,
    strategy,
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = ({ target }) =>
    (!popperElement?.contains(target) || closeOnClick) && onClick && setIsOpen((prev) => !prev);

  const handleBlur = ({ relatedTarget }) => !popperElement?.contains(relatedTarget) && onClick && setIsOpen(false);

  const handleMouseEnter = () => !onClick && setIsOpen(true);

  const handleMouseLeave = () => !onClick && setIsOpen(false);

  return (
    <div
      ref={setReferenceElement}
      role="button"
      tabIndex={0}
      data-id={`${dataId}-tooltip-container`}
      onClick={handleClick}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      css={tooltipContainer}
      {...(className && { className })}
      {...mainContainerProps}>
      {isOpen && (
        <BodyOverflow
          fixed
          className="tooltip-body-overflow"
          zIndex={zIndex}
          css={contentClassName}
          dataId={`${dataId}-tooltip-body-overflow`}>
          <div
            ref={setPopperElement}
            className="tooltip-content"
            data-id={`${dataId}-popup-tooltip-container`}
            css={popupTooltip(props)}
            style={styles.popper}
            {...attributes.popper}>
            {isFunction(content)
              ? content({
                  isOpen,
                  setIsOpen,
                  referenceElement,
                  popperElement,
                })
              : content}
            {!noArrow && (
              <div
                ref={setArrowElement}
                style={styles.arrow}
                css={arrow(props)}
                className="tooltip-arrow"
                data-id={`${dataId}-tooltip-arrow`}
              />
            )}
          </div>
        </BodyOverflow>
      )}
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
  className: PropTypes.string,
  placement: PropTypes.string,
  strategy: PropTypes.string,
  offset: PropTypes.array,
  noArrow: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  zIndex: PropTypes.number,
  contentClassName: PropTypes.any,
  mainContainerProps: PropTypes.object,
  dataId: PropTypes.string,
};

export default Tooltip;
