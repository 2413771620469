import {
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // BuySubscription
  HotelCheckout,
  MyBookings,
  MyBookingsDetails,
  MyProfile,
  SuccessCheckout,
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // SuccessCancelBooking
  TransactionDetails,
  TransactionsPage,
} from 'screens/private';
import { ROUTES } from 'enums';

export const PrivateRoutes = {
  hotels: {
    internal: [
      {
        //  REMOVE TAG HIDE FOR FIRST VERSION // hasSubscription &&
        url: ROUTES.HotelCheckout,
        component: HotelCheckout,
      },
      {
        //  REMOVE TAG HIDE FOR FIRST VERSION // hasSubscription &&
        url: ROUTES.SuccessCheckout,
        component: SuccessCheckout,
      },
    ],
  },
  myProfile: {
    internal: [
      {
        url: ROUTES.MyProfile,
        component: MyProfile,
      },
      // REMOVE TAG HIDE FOR FIRST VERSION{
      //   url: ROUTES.BuySubscription,
      //   component: BuySubscription,
      // },
    ],
  },
  bookings: {
    internal: [
      {
        url: ROUTES.MyBookingsDetails,
        component: MyBookingsDetails,
      },
      {
        url: ROUTES.MyBookings,
        component: MyBookings,
      },
      //  REMOVE TAG HIDE FOR FIRST VERSION
      //  hasSubscription &&{
      //   url: ROUTES.SuccessCancelBooking,
      //   component: SuccessCancelBooking,
      // },
    ],
  },
  transactions: {
    internal: [
      {
        url: ROUTES.TransactionsDetails,
        component: TransactionDetails,
      },
      {
        url: ROUTES.Transactions,
        component: TransactionsPage,
      },
    ],
  },
};
