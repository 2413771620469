export const CAROUSED_IMAGES = [
  'https://oneecosystem.blob.core.windows.net/voyage/apps/images/carousel-login-image-1.png',
  'https://oneecosystem.blob.core.windows.net/voyage/apps/images/carousel-login-image-2.png',
  'https://oneecosystem.blob.core.windows.net/voyage/apps/images/carousel-login-image-3.png',
  'https://oneecosystem.blob.core.windows.net/voyage/apps/images/carousel-login-image-4.png',
];

export const CAROUSEL_RESPONSIVE_DATA = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
};
