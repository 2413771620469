import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash-es';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image, Modal } from 'components';
import { CAROUSEL_RESPONSIVE_DATA } from './config';
import { benefitsModalContainer } from './styles';

const BenefitsModal = forwardRef((props, ref) => {
  const { allBenefits, selectedBenefits, dataId } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();
  const carouselRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  useEffect(() => {
    selectedBenefits && carouselRef?.current?.goToSlide(selectedBenefits?.id, true);
  }, [selectedBenefits?.id]);

  const filteredArray = (element) => allBenefits(getText)?.filter((el) => el.id !== element);
  const reorderedArray =
    selectedBenefits?.id === 2
      ? [selectedBenefits, ...orderBy(filteredArray(selectedBenefits?.id), ['id'], ['desc'])]
      : [selectedBenefits, ...filteredArray(selectedBenefits?.id)];

  return (
    <Modal ref={modalRef} closeIcon css={benefitsModalContainer} dataId={`${dataId}-benefits-modal`}>
      <Carousel
        ref={carouselRef}
        infinite
        showDots
        arrows
        renderButtonGroupOutside
        renderDotsOutside={false}
        transitionDuration={100}
        containerClass="carousel-slider"
        dotListClass="dot-list-style"
        responsive={CAROUSEL_RESPONSIVE_DATA}
        customLeftArrow={
          <Button small className="left-arrow" dataId={`${dataId}-benefits-modal-carousel-left-arrow`}>
            <Icon
              iconName="keyboard_arrow_left"
              color="gray500"
              size={16}
              dataId={`${dataId}-benefits-modal-carousel-left-arrow-icon`}
            />
          </Button>
        }
        customRightArrow={
          <Button small className="right-arrow" dataId={`${dataId}-benefits-modal-carousel-right-arrow`}>
            <Icon
              iconName="keyboard_arrow_right"
              color="gray500"
              size={16}
              dataId={`${dataId}-benefits-modal-carousel-right-arrow-icon`}
            />
          </Button>
        }>
        {reorderedArray.map((el, ind) => (
          <div key={ind}>
            <Image src={el.image} className="carousel-image" dataId={`${dataId}-benefits-modal-carousel-image`} />
            <div className="carousel-info-container" data-id={`${dataId}-benefits-modal-carousel-info-container`}>
              <h4 className="benefits-title" data-id={`${dataId}-benefits-modal-carousel-info-title`}>
                {el.title}
              </h4>
              <p data-id={`${dataId}-benefits-modal-carousel-info-description`}>{el.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </Modal>
  );
});

BenefitsModal.propTypes = {
  selectedBenefits: PropTypes.object,
  allBenefits: PropTypes.func,
  dataId: PropTypes.string,
};

export default BenefitsModal;
