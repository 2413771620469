export const BOOKING_STATUSES = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  REFUNDED: 'Refunded',
  FAILED: 'Failed',
  REJECTED: 'Rejected',
  PROCESSING_PAYMENT: 'ProcessingPayment',
  PAYMENT_FAILED: 'PaymentFailed',
  PROCESSING_REFUND: 'ProcessingRefund',
  PAYMENT_COMPLETED: 'PaymentCompleted',
  REJECTED_BY_PROVIDER: 'RejectedByProvider',
};

export const BOOKING_TYPES = {
  HOTEL: 'Hotel',
  FLIGHT: 'Flight',
  CAR: 'Car',
  CRUISE: 'Cruise',
  ACTIVITY: 'Activity',
};

export const TRAVELOPRO_ADULTS_TITLES = {
  MR: 'Mr',
  MRS: 'Mrs',
  MISS: 'Miss',
};

export const TRAVELOPRO_CHILDS_TITLES = {
  MASTER: 'Master',
  MISS: 'Miss',
};
