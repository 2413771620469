import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Col, Dropdown, Icon, Image, Row } from 'components';
import { DEFAULT_CURRENCY, ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { PRODUCTS_DATA } from './config';
import { footerContainer, footerLinkButton } from './styles';

const Footer = (props) => {
  const { className } = props;
  const getYear = new Date().getFullYear();
  const { getText, allEnvLanguages, changeLanguage, language } = useTranslations();
  const { fiatCurrencies, selectedCurrency } = useCurrencies((currenciesState) =>
    pick(currenciesState, ['fiatCurrencies', 'selectedCurrency']),
  );
  const history = useHistory();

  return (
    <footer css={footerContainer} {...(className && { className })} data-id="footer-container">
      <Row gap={16} className="first-container" dataId="footer-first-row-container">
        <Col lg={4} md={4} sm={6} xs={12} dataId="footer-first-row-column-logo">
          <Logo
            className="logo"
            data-id="footer-first-logo"
            onClick={() => {
              history.push(ROUTES.Hotels);
            }}
          />
        </Col>
        <Col lg={3} md={4} sm={6} xs={12} dataId="footer-first-row-column-one-voyage">
          <p className="footer-title" data-id="footer-first-row-column-one-voyage-title">
            One Voyage
          </p>
          <Row horizontalGap={20} dataId="footer-first-row-column-one-voyage-row-actions">
            {PRODUCTS_DATA?.map((el, ind) => (
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                key={ind}
                dataId={`footer-first-row-column-one-voyage-row-actions-column-${el.label}`}>
                <Button
                  small
                  disabled={el.disable}
                  type="link"
                  dataId={`footer-first-row-column-one-voyage-row-actions-column-button-${el.label}`}
                  css={footerLinkButton(el.disable)}
                  rightIcon={el.disable && <div className="soon-container">{getText('soon')}</div>}
                  onClick={() => history.push(`/${el.label}`)}>
                  {getText(el.label)}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg={2} md={4} sm={6} xs={12} dataId="footer-first-row-column-mobile-apps">
          <p className="footer-title" data-id="footer-first-row-column-mobile-app-title">
            {getText('downloadOurApp')}
          </p>
          <div className="apps-container" data-id="footer-first-row-column-mobile-app-section">
            <div
              role="button"
              tabIndex={0}
              data-id="footer-first-row-column-mobile-app-store-button"
              onClick={() => history.push(ROUTES.AppStore)}>
              <Image
                src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/app-store.svg"
                className="app-store-icon"
                dataId="footer-first-row-column-mobile-app-store-image"
              />
            </div>
            <div
              role="button"
              tabIndex={0}
              data-id="footer-first-row-column-mobile-google-store-button"
              onClick={() => history.push(ROUTES.GoogleStore)}>
              <Image
                src="https://oneecosystem.blob.core.windows.net/voyage/apps/images/google-play.png"
                className="icon"
                dataId="footer-first-row-column-mobile-google-store-image"
              />
            </div>
          </div>
        </Col>
        <Col
          lg={3}
          md={3}
          sm={6}
          xs={12}
          className="lang-currency-container"
          dataId="footer-first-row-column-languages-and-currencies">
          <p className="footer-title" data-id="footer-first-row-column-languages-and-currencies-title">
            {getText('languageAndCurrency')}
          </p>
          <div>
            <Dropdown
              noClear
              className="dropdown"
              dataId="footer-first-row-column-languages-dropdown"
              value={language}
              options={allEnvLanguages}
              displayKey={'name'}
              leftIcon={<Icon iconName="language" className="icon" size={28} />}
              onChange={(value) => changeLanguage(value.code)}
            />
            <Dropdown
              noClear
              className="dropdown"
              dataId="footer-first-row-column-currencies-dropdown"
              value={selectedCurrency ?? DEFAULT_CURRENCY}
              options={fiatCurrencies}
              displayKey={'code'}
              onChange={(value) => changeUserCurrency(value.code)}
            />
          </div>
        </Col>
      </Row>
      <div className="second-container" data-id="footer-container-rights-section">
        <p className="text" data-id="footer-container-rights-section-text">
          &nbsp; © {getYear} <span>OneVoyage, Inc. All Rights Reserved</span>
        </p>

        <div data-id="footer-container-rights-section-terms-policy-help-actions">
          <Button type="link" className="button" dataId="footer-container-rights-section-help-button">
            <a
              href="mailto: help@onevoyage.com"
              className="help-button"
              data-id="footer-container-rights-section-help-button-anchor-href">
              {getText('help')}
            </a>
          </Button>
          <Button
            type="link"
            className="button"
            dataId="footer-container-rights-section-policy-button"
            onClick={() => history.push(ROUTES.PrivacyPolicy)}>
            {getText('privacyPolicy')}
          </Button>
          <Button
            type="link"
            className="button"
            dataId="footer-container-rights-section-terms-button"
            onClick={() => history.push(ROUTES.TermsAndConditions)}>
            {getText('termsAndConditions')}
          </Button>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
