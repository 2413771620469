import { useTranslations } from '@veraio/strank';
import { camelCase } from 'lodash-es';
import { Tabs, Tab, BookingsTabsContent } from 'components';
import { BOOKING_STATUSES, ROUTES } from 'enums';
import { myBookingsContainer } from './styles';

const MyBookings = () => {
  const { getText } = useTranslations();

  return (
    <div css={myBookingsContainer}>
      <h2 className="title">{getText('myBookings')}</h2>
      <Tabs className="tabs-container" startingRoute={ROUTES.MyBookings}>
        <Tab
          url={`/${BOOKING_STATUSES.PENDING.toLowerCase()}`}
          label={getText(camelCase(BOOKING_STATUSES.PENDING))}
          component={() => (
            <BookingsTabsContent
              bookingStatus={[BOOKING_STATUSES.PROCESSING_PAYMENT, BOOKING_STATUSES.PAYMENT_COMPLETED]}
            />
          )}
        />
        <Tab
          url={`/${BOOKING_STATUSES.COMPLETED.toLowerCase()}`}
          label={getText(camelCase(BOOKING_STATUSES.COMPLETED))}
          component={() => <BookingsTabsContent bookingStatus={BOOKING_STATUSES.COMPLETED} />}
        />
        <Tab
          url={`/${BOOKING_STATUSES.REFUNDED.toLowerCase()}`}
          label={getText(camelCase(BOOKING_STATUSES.REFUNDED))}
          component={() => (
            <BookingsTabsContent bookingStatus={[BOOKING_STATUSES.REFUNDED, BOOKING_STATUSES.PROCESSING_REFUND]} />
          )}
        />
        <Tab
          url={`/${BOOKING_STATUSES.FAILED.toLowerCase()}`}
          label={getText(camelCase(BOOKING_STATUSES.FAILED))}
          component={() => <BookingsTabsContent bookingStatus={BOOKING_STATUSES.PAYMENT_FAILED} />}
        />
        <Tab
          url={`/${BOOKING_STATUSES.REJECTED.toLowerCase()}`}
          label={getText(camelCase(BOOKING_STATUSES.REJECTED))}
          component={() => <BookingsTabsContent bookingStatus={BOOKING_STATUSES.REJECTED_BY_PROVIDER} />}
        />
      </Tabs>
    </div>
  );
};

export default MyBookings;
