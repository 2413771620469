import { theme } from 'styles';

export const benefitsModalContainer = {
  '.modal-content-container': {
    padding: 0,

    '.close-icon': {
      zIndex: 1000,
    },
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.carousel-slider': {
    width: '100%',

    '.dot-list-style': {
      marginBottom: '30.5rem',

      '.react-multi-carousel-dot--active': {
        '& > button': {
          backgroundColor: theme.white,
          border: `1px solid ${theme.white}`,
        },
      },

      '& > li > button': {
        border: `1px solid ${theme.gray150}`,
        backgroundColor: theme.gray150,
        width: 12,
        height: 12,
      },
    },
  },

  '.left-arrow': {
    backgroundColor: theme.white,
    zIndex: 1000,
    position: 'absolute',
    top: '20%',
    left: 4,
    width: '3.6rem',
    height: '3.6rem',

    '&:hover': {
      backgroundColor: theme.gray400,
    },
  },

  '.right-arrow': {
    backgroundColor: theme.white,
    zIndex: 1000,
    position: 'absolute',
    top: '20%',
    right: 4,
    width: '3.6rem',
    height: '3.6rem',

    '&:hover': {
      backgroundColor: theme.gray400,
    },
  },

  '.carousel-image': {
    width: '100%',
    height: 260,
    borderRadius: '16px 16px 0 0',
  },

  '.carousel-info-container': {
    padding: '2.4rem',

    '.benefits-title': {
      marginBottom: '1rem',
    },
  },
};
