import PropTypes from 'prop-types';
import { commonStyles } from '../../../styles';
import { backgroundPicture, imageContainer, child } from './styles';

const Image = (props) => {
  const { children, src, alt, width, height, background, className, dataId, ...restProps } = props;

  return background ? (
    <div
      title={alt}
      css={backgroundPicture(props)}
      {...(className && { className })}
      {...restProps}
      data-id={`${dataId}-background-image-container`}>
      {children}
    </div>
  ) : children ? (
    <div
      {...(className && { className })}
      css={commonStyles(props)}
      {...restProps}
      data-id={`${dataId}-image-container`}>
      <img
        src={src}
        alt={alt}
        width={width || '100%'}
        height={height || 'auto'}
        css={imageContainer(props)}
        data-id={`${dataId}-img`}
      />
      <div css={child(props)} data-id={`${dataId}-image-child`}>
        {children}
      </div>
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      width={width || '100%'}
      height={height || 'auto'}
      {...(className && { className })}
      css={imageContainer(props)}
      data-id={`${dataId}-img-container`}
    />
  );
};

Image.propTypes = {
  children: PropTypes.any,
  background: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default Image;
