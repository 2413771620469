import { cloneElement, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, User } from 'components';
import { ROUTES } from 'enums';
import { useUserStore } from 'stores';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { useNavigationControls } from '../NavigationContext';
import { sideNavContainer, menuListContainer, sideNavHeader } from './styles';

const MenuList = ({ sideNavChilds, homeRoute, closeBreakPoint }) => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();
  const { setupSideNavControls } = useNavigationControls();
  const [isOpen, setIsOpen] = useState(window.innerWidth <= 767);
  const [isOpenNavigation, setIsOpenNavigation] = useState(false);
  const [breakPoint, setBreakPoint] = useState(closeBreakPoint);

  const previousWidthRef = useRef(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    setupSideNavControls({
      isOpen,
      isOpenNavigation,
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
      toggle: () => setIsOpen((prev) => !prev),
      changeBreakPoint: (val) => setBreakPoint(val),
      openNavigation: () => setIsOpenNavigation(true),
      closeNavigation: () => setIsOpenNavigation(false),
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isOpen, isOpenNavigation]);

  useEffect(() => {
    setBreakPoint(closeBreakPoint);
    setIsOpen(window.innerWidth >= closeBreakPoint);
  }, [closeBreakPoint]);

  const handleWindowResize = debounce((e) => {
    if (e.currentTarget?.innerWidth < breakPoint && previousWidthRef.current >= breakPoint) setIsOpen(false);
    else if (e.currentTarget?.innerWidth >= breakPoint && previousWidthRef.current < breakPoint) setIsOpen(true);
    previousWidthRef.current = e.currentTarget?.innerWidth;
  }, 500);

  const MenuListItems = sideNavChilds?.map((menu, i) =>
    cloneElement(menu, {
      key: `${menu.label}-${i}`,
      isOpen: isOpenNavigation,
    }),
  );

  return (
    <div css={sideNavContainer} role="navigation" aria-label="Main" data-id="navigation-menu-list-container">
      <header css={sideNavHeader} data-id="navigation-menu-list-header-mobile-container">
        <Link to={homeRoute} className="logo-container" data-id="navigation-menu-list-header-mobile-link">
          <Logo className="logo" data-id="navigation-menu-list-header-mobile-link-logo" />
        </Link>
        <div className="action-container" data-id="navigation-buttons-mobile-container">
          <Icon
            iconName={!isOpenNavigation ? 'density_medium' : 'close'}
            className="icon"
            dataId="navigation-buttons-mobile-icon"
            onClick={() => setIsOpenNavigation((prev) => !prev)}
          />
          {isAuthenticated ? (
            <User />
          ) : (
            <Button
              small
              className="login-styles"
              dataId="navigation-buttons-mobile-sign-in-action"
              linkTo={ROUTES.Login}>
              {getText('signIn')}
            </Button>
          )}
        </div>
      </header>
      <section
        className="menu-list-container"
        css={menuListContainer(isOpenNavigation)}
        data-id="navigation-mobile-menu-list-container">
        {MenuListItems}
      </section>
    </div>
  );
};

MenuList.propTypes = {
  sideNavChilds: PropTypes.array,
  footer: PropTypes.any,
  homeRoute: PropTypes.string,
  closeBreakPoint: PropTypes.number,
};

export default MenuList;
