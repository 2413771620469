export const PRODUCTS_DATA = [
  {
    id: 1,
    label: 'hotels',
  },
  {
    id: 2,
    label: 'benefits',
  },
  {
    id: 3,
    label: 'flights',
    disable: true,
  },
  {
    id: 4,
    label: 'contacts',
  },
  {
    id: 5,
    label: 'cars',
    disable: true,
  },
];
