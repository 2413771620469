import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { softLogout } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image } from 'components';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { container, profile, profileNavStyles } from './styles';

const User = () => {
  const [openContainer, setOpenContainer] = useState(false);
  const user = useUserStore();
  const history = useHistory();
  const { getText } = useTranslations();

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      data-id="user-header-container"
      onClick={() => setOpenContainer(true)}
      onBlur={hideContainer}>
      <div
        role="button"
        tabIndex={0}
        data-id="user-header-image-name-container"
        css={profileNavStyles}
        onClick={(event) => event.preventDefault()}>
        <Image
          src={user?.userInfo?.pictureUrl ?? user?.userInfo?.picThumbnailUrl ?? avatarLogo}
          className="avatar-image"
          dataId="user-header-image"
        />
        <p data-id="user-header-user-names">{user?.userInfo?.firstName}</p>
        <Icon material iconName="arrow_drop_down" size={16} dataId="user-header-user-icon" />
      </div>

      {openContainer && (
        <div css={container} data-id="user-header-opened-container">
          <div role="button" tabIndex={0} css={profile} data-id="user-header-opened-action-container">
            <div
              className="item"
              role="button"
              tabIndex={0}
              data-id="user-header-opened-action-my-profile"
              onClick={() => history.push('/my-profile')}>
              <Icon material iconName="account_circle" dataId="user-header-opened-my-profile-icon" />
              <h5 data-id="user-header-opened-action-my-profile-title">{getText('myProfile')}</h5>
            </div>
            <div
              className="item"
              role="button"
              tabIndex={0}
              data-id="user-header-opened-action-bookings"
              onClick={() => history.push('/bookings')}>
              <Icon material iconName="work" dataId="user-header-opened-my-bookings-icon" />
              <h5 data-id="user-header-opened-action-bookings-title">{getText('myBookings')}</h5>
            </div>
          </div>

          <Button
            small
            type="default"
            dataId="user-header-opened-sign-out-action"
            className="button"
            onClick={softLogout}>
            Sign out
          </Button>
        </div>
      )}
    </div>
  );
};

export default User;
