import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { add, div } from 'exact-math';
import moment from 'moment';
import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // isEmpty, isNil,
  isFunction,
  isObject,
  omit,
} from 'lodash-es';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useTranslations } from '@veraio/strank';
// import { useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Button,
  // Dropdown,
  DateRangePicker,
  Icon,
  Row,
  Col,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Tooltip,
  useUrlParams,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // PriceLabel
  // PriceStarsTooltipContent,
  // FiltersModal,
  SearchBar,
  Skeleton,
  VisitorsInput,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // UpdateSearchFilters,
} from 'components';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { DEFAULT_CURRENCY } from 'enums';
import { getLocations } from 'services';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { sortOptions } from './config';
import {
  searchContainer,
  // REMOVE TAG HIDE FOR FIRST VERSION
  //  pricesContainer, tooltipContent
} from './styles';

const DISPLAY_FORMAT = 'D MMM YYYY';

const ListingFilters = (props) => {
  const {
    hotels,
    // REMOVE TAG HIDE FOR FIRST VERSION
    //  filters,
    onApplyFilters,
    dataId,
  } = props;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const { clearQueryParams, queryParams } = useUrlParams();
  const { checkIn, checkOut, location, locationName, adults, children, rooms, childrenAges } = queryParams;
  const [params, setParams] = useState({});
  const queryParamsCheckIn = moment(queryParams?.checkIn).format('MM-D-YYYY');
  const queryParamsCheckOut = moment(queryParams?.checkOut).format('MM-D-YYYY');

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const convertedToLocalCurrencyPrice = (price) =>
  //   price && (
  //     <PriceLabel
  //       fiat={Number(price)}
  //       fiatOriginCurrency={DEFAULT_CURRENCY.code}
  //       fiatDesiredCurrency={selectedCurrency?.code}
  //       fiatOnly={false}
  //     />
  //   );

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const priceModalRef = useRef();
  // const modalRef = useRef();
  // const { getText } = useTranslations();
  // const [update, setUpdate] = useState(false);
  // const [selectedHotelStars, setSelectedHotelStars] = useState(3);
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const calcPrice = (hotel) =>
  //   hotel?.priceDetails?.savingPercentage > 0 && hotel?.priceDetails?.savingPercentage
  //     ? add(hotel?.priceDetails?.price ?? 0, hotel?.priceDetails?.savingsAmount ?? 0)
  //     : hotel?.priceDetails?.price;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const hotelPrices =
  //   hotels?.length > 0 && hotels?.map((el) => ({ priceDetails:{...el.priceDetails, price:Number(calcPrice(el?.priceDetails?.price)) } , stars: el.starRating, ...el }));
  // const filterByStar = (star) => hotelPrices && hotelPrices?.filter((el) => el.stars === star);
  // const averagePricePerHotelStars = (star) =>
  //   filterByStar(star)?.length > 0
  //     ? div(
  //         filterByStar(star)
  //           ?.map((el) => el?.priceDetails?.price)
  //           ?.reduce((acc, val) => add(acc, val, 0), 0),
  //         filterByStar(star)?.length,
  //       )
  //     : 0;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const minPricePerHotelStars = (star) =>
  //   filterByStar(star)?.length > 0 ? Math.min(...filterByStar(star).map((el) => Number(el?.priceDetails?.price))) : 0;
  // const maxPricePerHotelStars = (star) =>
  //   filterByStar(star)?.length > 0 ? Math.max(...filterByStar(star).map((el) => Number(el?.priceDetails?.price))) : 0;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const stars = {
  //   1: {
  //     prices: filterByStar(1) ?? 0,
  //     minPrice: minPricePerHotelStars(1)?.toFixed(2) ?? 0,
  //     maxPrice: maxPricePerHotelStars(1)?.toFixed(2) ?? 0,
  //     avgPrice: averagePricePerHotelStars(1)?.toFixed(2) ?? 0,
  //   },
  //   2: {
  //     prices: filterByStar(2) ?? 0,
  //     minPrice: minPricePerHotelStars(2)?.toFixed(2) ?? 0,
  //     maxPrice: maxPricePerHotelStars(2)?.toFixed(2) ?? 0,
  //     avgPrice: averagePricePerHotelStars(2)?.toFixed(2) ?? 0,
  //   },
  //   3: {
  //     prices: filterByStar(3) ?? 0,
  //     minPrice: minPricePerHotelStars(3)?.toFixed(2) ?? 0,
  //     maxPrice: maxPricePerHotelStars(3)?.toFixed(2) ?? 0,
  //     avgPrice: averagePricePerHotelStars(3)?.toFixed(2) ?? 0,
  //   },
  //   4: {
  //     prices: filterByStar(4) ?? 0,
  //     minPrice: minPricePerHotelStars(4)?.toFixed(2) ?? 0,
  //     maxPrice: maxPricePerHotelStars(4)?.toFixed(2) ?? 0,
  //     avgPrice: averagePricePerHotelStars(4)?.toFixed(2) ?? 0,
  //   },
  //   5: {
  //     prices: filterByStar(5) ?? 0,
  //     minPrice: minPricePerHotelStars(5)?.toFixed(2) ?? 0,
  //     maxPrice: maxPricePerHotelStars(5)?.toFixed(2) ?? 0,
  //     avgPrice: averagePricePerHotelStars(5)?.toFixed(2) ?? 0,
  //   },
  // };
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const handleChangeHotelStars = (val) => setSelectedHotelStars(val);
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const handleSortChange = (value) => {
  //   onApplyFilters({ ...queryParams, sortBy: value.value });
  // };

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const handleUpdate = (value) => setUpdate(value);

  const handleDelete = (options) => {
    options.inputProps.onDelete();
    clearQueryParams(['locationName', 'location']);
  };

  const handleSearchChange = (value) => {
    isObject(value) &&
      setParams((prev) => ({
        ...prev,
        location: value,
        locationName: value.display_name,
        latitude: value.lat,
        longitude: value.lon,
        city: value.city === '' ? value.name : value.city,
        country: value.country,
      }));

    isFunction(onApplyFilters) &&
      onApplyFilters({
        ...queryParams,
        locationName: value.display_name,
        location: value,
        latitude: value.lat,
        longitude: value.lon,
        city: value.city === '' ? value.name : value.city,
        country: value.country,
      });
  };

  const handleDateChange = (newParams) => {
    const checkInData = moment(newParams.startDate).format('MM-D-YYYY');
    const checkOutData = moment(newParams.endDate).format('MM-D-YYYY');
    const isNewDates = checkInData !== checkIn || checkOutData !== checkOut;

    isNewDates && setParams((prev) => ({ ...prev, checkIn: checkInData, checkOut: checkOutData }));
    isNewDates &&
      isFunction(onApplyFilters) &&
      onApplyFilters({
        ...queryParams,
        checkIn: checkInData ?? queryParamsCheckIn,
        checkOut: checkOutData ?? queryParamsCheckOut,
      });
  };

  const handleVisitorsChange = (value) => {
    const isChanged =
      value.adults !== queryParams.adults ||
      value.children !== queryParams.children ||
      value.rooms !== queryParams.rooms ||
      value.childrenAges !== queryParams.childrenAges;

    isChanged &&
      setParams((prev) => ({
        ...prev,
        adults: value.adults,
        children: value.children,
        rooms: value.rooms,
        childrenAges: value.childrenAges,
      }));

    isChanged &&
      isFunction(onApplyFilters) &&
      onApplyFilters({
        ...queryParams,
        adults: value.adults,
        children: value.children,
        rooms: value.rooms,
        childrenAges: value.childrenAges,
      });
  };

  return (
    <div css={searchContainer} data-id={`${dataId}-listing-search-container`}>
      {/*REMOVE TAG HIDE FOR FIRST VERSION {update && <h4>{getText('updateSearch')}</h4>} */}
      {hotels ? (
        <Row verticalGap={8} className="listing-filters-container" dataId={`${dataId}-listing-search-row`}>
          <Col md={12} lg={5} dataId={`${dataId}-listing-search-column`}>
            <SearchBar
              className="search-bar"
              dataId={`${dataId}-listing-search-bar`}
              placeholder="Where are you going?"
              displayKey={'display_name'}
              value={locationName ?? location?.display_name ?? params?.location?.display_name}
              getOptions={(input) => getLocations({ name: input })}
              renderSuggestion={(val) => (
                <div
                  className="suggestion"
                  data-id={`${dataId}-listing-search-suggestion`}
                  {...val.itemProps}
                  role={val.itemProps.role}
                  onClick={() => {
                    handleSearchChange(val.item);
                    val.suggestionItemProps.onClick(val.item);
                  }}>
                  <div className="icon-container" data-id={`${dataId}-listing-search-suggestion-icon-container`}>
                    <Icon
                      material
                      iconName="location_on"
                      size={16}
                      color="black"
                      dataId={`${dataId}-listing-search-suggestion-icon`}
                    />
                  </div>
                  {val.item.display_name}
                </div>
              )}
              renderInput={(val) => (
                <div {...val.containerProps} data-id={`${dataId}-listing-search-render-input`}>
                  <label htmlFor={val.inputProps.id} data-id={`${dataId}-listing-search-render-input-label`}>
                    <Icon {...val.iconProps} dataId={`${dataId}-listing-search-render-icon`} />
                  </label>
                  <input
                    {...omit(val.inputProps, 'onFocus', 'onDelete')}
                    data-id={`${dataId}-listing-search-render-input-input`}
                  />
                  <label htmlFor={val.inputProps.id} data-id={`${dataId}-listing-search-render-label`}>
                    <Icon
                      material
                      size={20}
                      iconName="close"
                      css={val.iconProps.css}
                      dataId={`${dataId}-listing-search-render-icon`}
                      onClick={() => handleDelete(val)}
                    />
                  </label>
                </div>
              )}
            />
          </Col>
          <Col sm={12} md={6} lg={3} dataId={`${dataId}-listing-date-range-picker-column`}>
            <DateRangePicker
              showClear={false}
              noFuture={false}
              withCalendarIcon
              startDate={checkIn}
              endDate={checkOut}
              onChange={handleDateChange}
              minDate={moment()}
              displayFormat={DISPLAY_FORMAT}
              className="date-range-picker"
              dataId={`${dataId}-listing-date-range-picker`}
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="visitors-container" dataId={`${dataId}-listing-visitors-column`}>
            <VisitorsInput
              values={{
                adults: params?.adults ? params?.adults : adults,
                children: params?.children ? params?.children : children,
                rooms: params?.rooms ? params?.rooms : rooms,
                childrenAges: params?.childrenAges ? params.childrenAges : childrenAges,
              }}
              onChange={handleVisitorsChange}
              tooltipClassName="tooltip"
              className="edit-container"
              dataId={`${dataId}-listing-visitors-input`}
            />
            {/*REMOVE TAG HIDE FOR FIRST VERSION <Icon material iconName="edit" className="icon" onClick={() => setUpdate(true)} /> */}
          </Col>
        </Row>
      ) : (
        <Skeleton
          width="100%"
          height={48}
          className="loading-filters-container"
          dataId={`${dataId}-listing-visitors-skeleton`}
        />
      )}
      {/*  REMOVE TAG HIDE FOR FIRST VERSION  {update && (
        <UpdateSearchFilters onUpdate={handleUpdate} onApplyFilters={onApplyFilters} onClose={() => setUpdate(false)} />
      )}
       <Row verticalGap={16} horizontalGap={8} className="price-row">
       REMOVE TAG HIDE FOR FIRST VERSION
        <Col sm={12} md={6} lg={7}>
          {hotels?.length > 0 && (
            <h4 className="hotel-title">{`${hotels?.length} ${getText('hotels').toLowerCase()}`}</h4>
          )}
          {isNil(hotels) && <Skeleton width={100} height={20} className="hotel-title" />}
          {hotels?.length > 0 && (
            <div css={pricesContainer}>
               <p>
                {convertedToLocalCurrencyPrice(stars[selectedHotelStars]?.avgPrice)}{' '}
                <span>{getText('averagePerNightByStars', { hotelStarts: selectedHotelStars })}</span>
              </p>
              <Tooltip
                placement="bottom"
                contentClassName={tooltipContent}
                content={
                  <PriceStarsTooltipContent
                    ref={priceModalRef}
                    destination={hotels}
                    starsData={stars}
                    selectedHotelStars={selectedHotelStars}
                    onSelectHotelStars={handleChangeHotelStars}
                  />
                }>
                <Icon material iconName="arrow_drop_down" size={16} />
              </Tooltip>

              <p className="subtitle">{getText('allPricesAreIn', { currency: selectedCurrency?.code })}</p>
            </div>
          )}
          {isNil(hotels) && <Skeleton width={400} height={20} className="hotel-title" />}
        </Col>
        REMOVE TAG HIDE FOR FIRST VERSION
        <Col sm={12} md={6} lg={3}>
          {hotels?.length > 0 && (
            <Dropdown
              className="dropdown"
              placeholder={`${getText('sortBy')}`}
              value={queryParams?.sortBy}
              options={sortOptions(getText)}
              onChange={(value) => handleSortChange(value)}
            />
          )}
          {isNil(hotels) && <Skeleton width="100%" height={49} />}
        </Col>
        REMOVE TAG HIDE FOR FIRST VERSION
        <Col sm={12} md={6} lg={2} displayFlex justify="flex-end">
          {hotels?.length > 0 && (
            <Button
              leftIcon={{
                material: true,
                iconName: 'tune',
                size: 20,
                className: 'icon',
              }}
              type="info"
              className="button"
              onClick={() => modalRef.current?.open()}>
              {getText('filters')}
            </Button>
          )}
          {isEmpty(filters) && <Skeleton width={60} height={42} className="button" />}
        </Col>
      </Row>
      REMOVE TAG HIDE FOR FIRST VERSION
      {filters && <FiltersModal ref={modalRef} filters={filters} onApplyFilters={onApplyFilters} />} */}
    </div>
  );
};

ListingFilters.propTypes = {
  hotels: PropTypes.array,
  filters: PropTypes.object,
  onApplyFilters: PropTypes.func,
  dataId: PropTypes.string,
};

export default ListingFilters;
