export const MEMBER_BENEFITS = (getText) => [
  {
    id: 1,
    icon: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/lounge-access-icon.svg',
    image: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/airport-lounge-access-image.png',
    title: getText('airportLoungeAccess'),
    subtitle: getText('airportLoungeAccessDescription'),
    description: getText('airportLoungeAccessAllDescription'),
  },
  {
    id: 2,
    icon: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/price-guarantee-icon.svg',
    image: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/price-guarantee-image.png',
    title: getText('priceGuarantee'),
    subtitle: getText('priceGuaranteeDescription'),
    description: getText('priceGuaranteeAllDescription'),
  },
  {
    id: 3,
    icon: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/booking-consierge-icon.svg',
    image:
      'https://oneecosystem.blob.core.windows.net/voyage/apps/images/concierge-services-for-group-travel-image.png',
    title: getText('groupBookingConcierge'),
    subtitle: getText('groupBookingConciergeDescription'),
    description: getText('groupBookingConciergeAllDescription'),
  },
];

export const MORE_FROM_ONE_VOYAGE = (getText) => [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/flying-image.png',
    title: getText('saveWhileFlying'),
    subtitle: getText('saveWhileFlyingDescription'),
    isComingSoon: true,
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/rent-a-cat-image.png',
    title: getText('topDealsForRentACar'),
    subtitle: getText('topDealsForRentACarDescription'),
    isComingSoon: true,
  },
];

export const REVIEW_RATE_DATA = [
  {
    id: 1,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-1.png',
    reservationAddress: 'Phuket, Thailand',
    reviewRating: 5,
  },
  {
    id: 2,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-2.png',
    reservationAddress: 'Sofia, Bulgaria',
    reviewRating: 5,
  },
  {
    id: 3,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-3.png',
    reservationAddress: 'Berlin, Germany',
    reviewRating: 5,
  },
  {
    id: 4,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-4.png',
    reservationAddress: 'London, UK',
    reviewRating: 5,
  },
  {
    id: 5,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-5.png',
    reservationAddress: 'Phuket, Thailand',
    reviewRating: 5,
  },
  {
    id: 6,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-6.png',
    reservationAddress: 'Syndey, Australia',
    reviewRating: 5,
  },
  {
    id: 7,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-7.png',
    reservationAddress: 'Phuket, Thailand',
    reviewRating: 5,
  },
  {
    id: 8,
    userImage: 'https://oneecosystem.blob.core.windows.net/voyage/apps/images/ratedUsers/user-8.png',
    reservationAddress: 'Sofia, Bulgaria',
    reviewRating: 5,
  },
];
