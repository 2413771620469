import { HotelDetails, HotelsListing, PrivacyPolicy, TermsAndConditions } from 'screens/public';
import { ROUTES } from 'enums';

export const PublicRoutes = {
  hotels: {
    internal: [
      {
        url: ROUTES.HotelsListing,
        component: HotelsListing,
      },
      {
        url: ROUTES.HotelDetails,
        component: HotelDetails,
      },
    ],
  },
  shared: {
    internal: [
      {
        url: ROUTES.PrivacyPolicy,
        component: PrivacyPolicy,
      },
      {
        url: ROUTES.TermsAndConditions,
        component: TermsAndConditions,
      },
    ],
  },
};
